import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import ProjectDetailView from './ProjectDetailView'
import ProjectListView from './ProjectListView'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const Projects = ({ className, ...props }) => {
  const classes = useStyles()
  const params = useParams()

  return (
    <div className={clsx(classes.root, className)}>
      {params?.id ? <ProjectDetailView id={params.id} /> : <ProjectListView />}
    </div>
  )
}

export default Projects
