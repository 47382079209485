import './assets/css/index.css'
import i18n from './i18n'

import { ThemeProvider } from '@material-ui/styles'
import React, { useState } from 'react'
import { BrowserRouter, Switch, Redirect, useLocation } from 'react-router-dom'
import validate from 'validate.js'
import { useAuthUser, AuthUserContext, SnackbarProvider, LanguageContext, usePageViews } from '@leanlancer/common'
import { Routes } from './constants'
import { map } from 'lodash'
import { muiTheme, FullPageLoader } from '@leanlancer/ui'

validate.validators = {
  ...validate.validators,
  checked: (value, options) => {
    if (value !== true) {
      return options.message || 'must be checked'
    }
  }
}

const AppWrappers = ({ children }) => {
  const [authUser, authLoading] = useAuthUser({ registrationData: { clientAppSignIn: true } })
  const [lang, setLang] = useState(i18n.languages?.[0]?.substr(0, 2) || 'en')

  if (authLoading) {
    return <FullPageLoader />
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <AuthUserContext.Provider value={authUser}>
        <LanguageContext.Provider value={{
          lang,
          changeLanguage: (lang) => {
            setLang(lang)
            i18n.changeLanguage(lang)
          }
        }}
        >
          <SnackbarProvider>
            <BrowserRouter>
              {children}
            </BrowserRouter>
          </SnackbarProvider>
        </LanguageContext.Provider>
      </AuthUserContext.Provider>
    </ThemeProvider>
  )
}

const App = () => {
  const location = useLocation()
  usePageViews({ location })
  return (
    <Switch>
      {map(Routes, 'render')}
      <Redirect to='/not-found' />
    </Switch>

  )
}

export default () => (
  <AppWrappers>
    <App />
  </AppWrappers>
)
