import React from 'react'
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core'
import { amber, red } from '@material-ui/core/colors'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    margin: theme.spacing(0.5, 0)
  },
  dueDate: {
    color: theme.palette.text.secondary
  },
  overdue: {
    color: red[500]
  },
  dueSoon: {
    color: amber[500]
  }
}))
const KanbanTaskCard = ({ task }) => {
  const { title, milestone, startDate, endDate, status } = task
  const classes = useStyles()
  const { t } = useTranslation('common')

  const isDateLongOverdue = (date) => {
    const currentDate = moment()
    return moment(date).isBefore(currentDate.subtract(2, 'weeks'), 'day')
  }

  const isDateOverdue = (date) => {
    const currentDate = moment()
    return moment(date).isBefore(currentDate, 'day')
  }

  const renderDate = (date) => {
    return moment(date).format('L')
  }

  const renderDueDate = (date) => {
    const formattedDate = renderDate(date)
    const isLongOverdue = isDateLongOverdue(date)
    const isOverdue = isDateOverdue(date)

    let dueDateClass = ''
    if (isLongOverdue && status !== 'completed' && status !== 'cancelled') {
      dueDateClass = classes.overdue
    } else if (isOverdue && status !== 'completed' && status !== 'cancelled') {
      dueDateClass = classes.dueSoon
    }

    return (
      <Typography
        variant='body2'
        className={`${classes.dueDate} ${dueDateClass}`}
        display='inline'
      >
        {formattedDate}
      </Typography>
    )
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='body2'>{milestone}</Typography>
        <Typography variant='body2'>
          {t('common:projects.startDate')} {startDate ? renderDate(startDate) : '-'}
        </Typography>
        <Typography variant='body2'>
          {t('common:projects.dueDate')} {endDate ? renderDueDate(endDate) : '-'}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default KanbanTaskCard
