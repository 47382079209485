import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
// import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useLeadData from '../../../../hooks/useLeadData'
import { Button } from '@material-ui/core'
import Flex from '@leanlancer/ui/dist/components/atoms/Flex/Flex'
import IosIcon from '../../icons/ios.svg'
import AndroidIcon from '../../icons/android.svg'
import WebIcon from '../../icons/internet.svg'
import OtherIcon from '../../icons/other.svg'

const AppPlatforms = ({ pathwayStack, addToStack, goNext, ...props }) => {
  const { t } = useTranslation('client')
  const [leadSnap, leadData] = useLeadData()
  const [selected, setSelected] = useState(leadData?.appPlatforms || [])

  const handleSelectItem = (item) => (e) => {
    e.preventDefault()
    if (selected.includes(item)) {
      setSelected(selected.filter(i => i !== item))
    } else {
      setSelected([...selected, item])
    }
  }

  const handleSubmit = async () => {
    leadSnap.ref.update({
      type: 'app',
      appPlatforms: selected
    })
    goNext('purpose')
  }

  const Actions = () => (
    <Flex dir='rtl'>
      <Button
        disabled={selected.length < 1}
        variant='outlined'
        color='primary'
        onClick={handleSubmit}
      >{t('common:inputs.next')}
      </Button>
    </Flex>
  )

  return (
    <>
      <FunnelQuestion
        title={t('leads.app.platforms.title')}
        subtitle={t('leads.app.platforms.subtitle')}
        questionHeader={t('leads.app.platforms.questionHeader')}
        progress={25}
        actions={<Actions />}
      >
        <FunnelSelectCardGrid
          centered
          cards={[
            {
              title: t('leads.app.platforms.answerWeb'),
              imgSrc: WebIcon,
              onSelect: handleSelectItem('web'),
              selected: selected.includes('web')
            },
            {
              title: t('leads.app.platforms.answerIOS'),
              imgSrc: IosIcon,
              onSelect: handleSelectItem('ios'),
              selected: selected.includes('ios')
            },
            {
              title: t('leads.app.platforms.answerAndroid'),
              imgSrc: AndroidIcon,
              onSelect: handleSelectItem('android'),
              selected: selected.includes('android')
            },
            {
              title: t('common:other'),
              imgSrc: OtherIcon,
              onSelect: handleSelectItem('other'),
              selected: selected.includes('other')
            }
          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default AppPlatforms
