import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
// import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useLeadData from '../../../../hooks/useLeadData'
import { Button } from '@material-ui/core'
import Flex from '@leanlancer/ui/dist/components/atoms/Flex/Flex'
import GamingIcon from '../../icons/game-controller.svg'
import BusinessIcon from '../../icons/handshake.svg'
import ShoppingIcon from '../../icons/shopping-cart.svg'
import OtherIcon from '../../icons/other.svg'

const AppPurpose = ({ pathwayStack, addToStack, goNext, ...props }) => {
  const { t } = useTranslation('client')
  const [leadSnap, leadData] = useLeadData()
  const [selected, setSelected] = useState(leadData?.appPurpose || [])

  const handleSelectItem = (item) => (e) => {
    e.preventDefault()
    if (selected.includes(item)) {
      setSelected(selected.filter(i => i !== item))
    } else {
      setSelected([...selected, item])
    }
  }

  const handleSubmit = async () => {
    leadSnap.ref.update({
      type: 'app',
      appPurpose: selected
    })
    goNext('design')
  }

  const Actions = () => (
    <Flex dir='rtl'>
      <Button
        disabled={selected.length < 1}
        variant='outlined'
        color='primary'
        onClick={handleSubmit}
      >{t('common:inputs.next')}
      </Button>
    </Flex>
  )

  return (
    <>
      <FunnelQuestion
        title={t('leads.app.purpose.title')}
        subtitle={t('leads.app.purpose.subtitle')}
        questionHeader={t('leads.app.purpose.questionHeader')}
        progress={50}
        actions={<Actions />}
      >
        <FunnelSelectCardGrid
          centered
          cards={[
            {
              title: t('leads.app.purpose.answerGaming'),
              imgSrc: GamingIcon,
              onSelect: handleSelectItem('gaming'),
              selected: selected.includes('gaming')
            },
            {
              title: t('leads.app.purpose.answerBusiness'),
              imgSrc: BusinessIcon,
              onSelect: handleSelectItem('business'),
              selected: selected.includes('business')
            },
            {
              title: t('leads.app.purpose.answerShopping'),
              imgSrc: ShoppingIcon,
              onSelect: handleSelectItem('shopping'),
              selected: selected.includes('shopping')
            },
            {
              title: t('common:other'),
              imgSrc: OtherIcon,
              onSelect: handleSelectItem('other'),
              selected: selected.includes('other')
            }
          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default AppPurpose
