// TODO refactor this file, it is a common view
import React, { useState, useEffect, useContext } from 'react'
import { Link as RouterLink, withRouter, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import validate from 'validate.js'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useTranslation } from 'react-i18next'
import { FacebookSignInButton, GoogleSignInButton } from '@leanlancer/ui'
import { LanguageContext } from '@leanlancer/common'

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.secondary.dark,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  socialButtons: {
    marginBottom: theme.spacing(3)
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  policyLink: {
    textDecoration: 'underline'
  }
}))

const SignUp = props => {
  const history = useHistory()
  const classes = useStyles()
  const { lang } = useContext(LanguageContext)

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })
  const [signUpError, setSignUpError] = useState(null)
  const { t } = useTranslation('client')

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleSignUp = async event => {
    event.preventDefault()
    console.log(formState)
    const { email, password } = formState.values
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password)
      history.push('/')
    } catch (error) {
      console.error('-> : error', error)
      setSignUpError(error.message)
    }
  }

  const hasError = field =>
    !!(formState.touched[field] && formState.errors[field])

  const tcLink = lang === 'en' ? 'https://leanlancer.com/en/terms-and-conditions-freelancer/' : 'https://leanlancer.com/agb-freelancer/'

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.quoteContainer}
          item
          lg={5}
        >
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography
                className={classes.quoteText}
                variant='h1'
              >
                {t('signIn.quote')}
              </Typography>
              <div className={classes.person}>
                <Typography
                  className={classes.name}
                  variant='body1'
                >
                  {t('signIn.quoteAuthor')}
                </Typography>
                <Typography
                  className={classes.bio}
                  variant='body2'
                >
                  {t('signIn.quoteProfession')}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className={classes.content}
          item
          lg={7}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSignUp}
              >
                <Typography
                  className={classes.title}
                  variant='h2'
                  gutterBottom
                >
                  {t('signUp.title')}
                </Typography>
                <Typography
                  color='textSecondary'
                  gutterBottom
                >
                  {t('signUp.subline')}
                </Typography>
                <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}
                >
                  <Grid item>
                    <FacebookSignInButton
                      onSignIn={() => history.push('/')}
                      onError={(error) => setSignUpError(error.message)}
                      label={t('signUp.facebook')}
                    />
                  </Grid>
                  <Grid item>
                    <GoogleSignInButton
                      onSignIn={() => history.push('/')}
                      onError={(error) => setSignUpError(error.message)}
                      label={t('signUp.google')}
                    />
                  </Grid>
                </Grid>
                <Typography
                  className={classes.sugestion}
                  color='textSecondary'
                  variant='body1'
                >
                  {t('signUp.signUpEmail')}
                </Typography>
                <TextField
                  className={classes.textField}
                  error={hasError('firstName')}
                  fullWidth
                  helperText={
                    hasError('firstName') ? formState.errors.firstName[0] : null
                  }
                  label={t('signUp.firstNameLabel')}
                  name='firstName'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.firstName || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('lastName')}
                  fullWidth
                  helperText={
                    hasError('lastName') ? formState.errors.lastName[0] : null
                  }
                  label={t('signUp.lastNameLabel')}
                  name='lastName'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.lastName || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label={t('signUp.emailLabel')}
                  name='email'
                  onChange={handleChange}
                  type='text'
                  value={formState.values.email || ''}
                  variant='outlined'
                />
                <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label={t('signUp.passwordLabel')}
                  name='password'
                  onChange={handleChange}
                  type='password'
                  value={formState.values.password || ''}
                  variant='outlined'
                />
                <div className={classes.policy}>
                  <Checkbox
                    checked={formState.values.policy || false}
                    className={classes.policyCheckbox}
                    color='primary'
                    name='policy'
                    onChange={handleChange}
                  />
                  <Typography
                    className={classes.policyText}
                    color='textSecondary'
                    variant='body1'
                  >
                    {t('signUp.tcRead')}{' '}
                    <a
                      target='__blank'
                      className={classes.policyLink}
                      href={tcLink}
                    >
                      <Typography variant='h6' component='span'>
                        {t('signUp.tc')}
                      </Typography>
                    </a>{' '}{t('signUp.tcAfter')}
                  </Typography>
                </div>
                {hasError('policy') && (
                  <FormHelperText error>
                    {formState.errors.policy[0]}
                  </FormHelperText>
                )}
                <Button
                  className={classes.signUpButton}
                  color='primary'
                  disabled={!formState.isValid}
                  fullWidth
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  {t('signUp.submit')}
                </Button>
                {signUpError && (
                  <FormHelperText error>
                    {signUpError}
                  </FormHelperText>
                )}
                <Typography
                  color='textSecondary'
                  variant='body1'
                >
                  {t('signUp.hasAccount')}{' '}
                  <Link
                    component={RouterLink}
                    to='/sign-in'
                    variant='h6'
                  >
                    {t('signUp.signIn')}
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SignUp.propTypes = {
  history: PropTypes.object
}

export default withRouter(SignUp)
