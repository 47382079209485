const resources = {
  en: {
    client: {
      home: {
        welcome: 'Welcome to LeanLancer!',
        greeting: `Nice that you are participating in our Early Access Program!
        As you will soon notice, most of the features are not yet available such as projects or invoices.
        However you can already create a profile and we will contact you as soon as we have a job for you - so hopefully very soon!`,
        next: 'So, what happens now?',
        step1Title: '1. Fill out profile',
        step1Content: 'Once the basic information about your company has been filled out, you can start placing requests for your projects.',
        step2Title: '2. Request a quote',
        step2Content: 'In the next step you can request a non-binding offer. We will guide you through the options and services according to your knowledge. If you should get stuck on a point, one of our employees is always at your side.',
        step3Title: '3. Consultation and cost estimate',
        step3Content: 'After you have requested the non-binding quote, one of our sales representatives will contact you to make sure that all your wishes are taken into account and that there are no misunderstandings during the project. Based on this, we will prepare a quote and send it to you by email. If you agree, the project starts.',
        step4Title: '4. Quality Assurance',
        step4Content: 'After the freelancer has completed the tasks, we subject them to a detailed examination and compare them with the agreed service. In this way, we ensure optimal quality and can guarantee that you will receive exactly the product that you had in mind.',
        step5Title: '5. Payment',
        step5Content: 'When the services have been delivered to the agreed quality, we will send you an invoice. Please note that for larger sums we will issue smaller partial invoices. This is to ensure that our freelancers are paid on time. This is not only fair, but also increases their motivation and thus the quality of the service.'
      },
      signIn: {
        quote: '“The person born with a talent they are meant to use will find their greatest happiness in using it.”',
        quoteAuthor: 'Johann Wolfgang von Goethe',
        quoteProfession: 'German Poet',
        title: 'Sign In',
        subline: 'Sign in with social media',
        loginFacebook: 'Login with Facebook',
        loginGoogle: 'Login with Google',
        loginEmail: 'or login with email address',
        submit: 'Sign in now',
        noAccount: "Don't have an account?",
        signUp: 'Sign up',
        emailLabel: 'Email address',
        passwordLabel: 'Password',
        forgotPassword: 'Forgot Password?',
        passwordResetSent: 'An email has been sent with password reset instructions',
        passwordResetEmailMissing: 'Enter you email address to reset password'
      },
      signUp: {
        title: 'Create new account',
        subline: 'Sign up with social media',
        firstNameLabel: 'First name',
        lastNameLabel: 'Last name',
        emailLabel: 'Email address',
        passwordLabel: 'Password',
        tcRead: 'I have read the',
        tc: 'Terms and Conditions',
        tcAfter: '',
        submit: 'Sign up now',
        hasAccount: 'Have an account?',
        signIn: 'Sign in',
        google: 'Sign up with Google',
        facebook: 'Sign up with Facebook',
        signUpEmail: 'or sign up using email'
      },
      profile: {
        save: 'Save',
        savedAlert: 'Saved!',
        general: { title: 'General Information' },
        skills: {
          title: 'Skills',
          subheader: 'Create a list of keywords that outline your relevant skills',
          placeholder: 'Enter your skill here (e.g ReactJS or MongoDB)'
        },
        biography: {
          title: 'Biography',
          subheader: 'Write a little about yourself',
          placeholder: 'Write some information about your background and personality'
        },
        languages: {
          title: 'Languages',
          subheader: 'Languages you can work or communicate in',
          add: 'Add Another Language',
          proficieny: 'Proficieny',
          native: 'Native',
          fluent: 'Fluent',
          proficient: 'Proficient',
          basic: 'Basic'
        },
        cv: {
          title: 'CV Upload',
          subheader: 'Upload you CV here for review by our staff'
        }
      },
      invoices: {
        noInvoices: 'You have no invoices yet',
        invoiceNumber: 'Invoice Number',
        clientName: 'Client Name',
        issueDate: 'Issue Date',
        dueDate: 'Due Date',
        description: 'Description',
        quantity: 'Quantity',
        unitPrice: 'Unit Price',
        subtotal: 'Subtotal',
        tax: 'Tax',
        total: 'Total',
        paymentDetails: 'Payment Details',
        businessName: 'Business Name',
        address: 'Address',
        phone: 'Phone',
        email: 'Email',
        website: 'Website',
        bank: 'Bank'
      },
      jobOffers: {
        createJobOffer: 'Create Job Offer',
        title: 'Title',
        company: 'Company',
        description: 'Description',
        requirement: 'Requirement',
        requirements: 'Requirements',
        active: 'Active',
        addRequirement: 'Add Requirement',
        budget: 'Budget',
        duration: 'Duration',
        contact: 'Contact',
        contactName: 'Contact Name',
        contactEmail: 'Contact Email',
        contactPhone: 'Contact Phone',
        editJobOffer: 'Edit Job Offer',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        remove: 'Remove',
        noOffers: 'You have no job offers yet',
        addJobOffer: 'Add Job Offer'
      },
      settings: {
        authentication: {
          title: 'Authentication',
          subtitle: 'From here you can add and edit login methods',
          passwordReset: 'Reset Password',
          passwordMismatch: 'These passwords do not match',
          linkGoogle: 'Link Google Account',
          googleLinked: 'Google acccount linked',
          googleLinkedSuccess: 'Google acccount successfully linked',
          linkFacebook: 'Link Facebook Account',
          facebookLinked: 'Facebook account linked',
          facebookLinkedSuccess: 'Facebook account successfully linked',
          reauthDialogTitle: 'Sign in again required for security reasons',
          reauthWithPassword: 'Sign in using password',
          reauthSuccess: 'Successfully signed in again. You may now link accounts',
          setPassword: 'Set a password for your account',
          repeatPassword: 'Repeat password',
          setPasswordSubmit: 'Set Password',
          resetPasswordSubmit: 'Reset Password'
        }
      },
      projects: {
        projectMembers: 'Project Members',
        skills: 'Skills',
        addTask: 'Add Task',
        editTask: 'Edit Task',
        addTaskSuccess: 'Task successfully added',
        addTaskError: 'Error adding task',
        taskUpdated: 'Task successfully updated',
        taskUpdateError: 'Error updating task'
      },
      leads: {
        start: {
          title: 'Tell us what you are looking for',
          selectServices: 'I know what services I want!',
          selectGoals: 'I know what outcomes I want!'
        },
        goals: {
          title: 'What are your goals?',
          subtitle: 'You can select from multiple options',
          brandAwareness: 'Brand Awareness',
          reach: 'Reach',
          webTraffic: 'Web Traffic',
          engagement: 'Engagement',
          appInstalls: 'App Installs',
          leadGeneration: 'Lead Generation',
          messages: 'Messages',
          sales: 'Sales',
          storeVisits: 'Store Visits',
          other: 'Other'
        },
        services: {
          title: 'Choose your services',
          subtitle: 'You can select from multiple options',
          onlineMarketing: 'Online Marketing',
          development: 'Development',
          graphicDesign: 'Graphic Design',
          copywriting: 'Copywriting',
          translations: 'Translations',
          other: 'Other'
        },
        describeRequest: {
          title: 'Describe your request',
          jobTitle: 'Job Title',
          jobDescription: 'Job Description'
        },
        almostThere: {
          title: 'Almost There',
          projectPeriod: 'Project Period',
          projectStart: 'Start',
          projectEnd: 'End',
          approxBudget: 'Approxitmate Budget',
          additionalInfo: 'Anything else you would like to tell us?'
        },
        verification: {
          title: 'Verifying your request',
          message: 'Great! We can make your project happen! Continue to get a free consultation'
        },
        contact: {
          title: 'Contact Details',
          trustBox1: 'Pool of verified freelancers',
          trustBox2: 'Professional project management',
          trustBox3: 'High quality through standardized processes',
          trustBox4: 'Pay only when milestones are reached',
          trustBox5: 'All projects covered by insurance'
        },
        thankYou: {
          title: 'What happens now?',
          subtitle: 'We have recieved your request and a team member will review it as soon as possible',
          step1: {
            title: '1. Contact',
            body: 'If you supplied your phone number we will give you a call within the next 3 working days. Otherwise we will send you an email to set up an appointment and verify your request.'
          },
          step2: {
            title: '2. Freelancer Selection',
            body: 'As soon as we have defined all tasks we will select the suiting freelancer(s) for your project. The selection is based only on skills.'
          },
          step3: {
            title: '3. Signing the Contract',
            body: 'After we have put your team together we will send a contract to you to sign. The contract includes an overview of all tasks and prices.'
          },
          step4: {
            title: '4. Project Kickoff',
            body: 'Upon receiving the signed contract we will start working on your project. During the whole duration a skilled project manager is at your disposal.'
          },
          backToHome: 'Back To Home',
          createAccount: 'Create Account'
        },
        website: {
          start: {
            title: 'Begin creating your website today',
            subtitle: 'We will provide a quote - free of charge and without obligation',
            questionHeader: 'Do you already have a website?'
          },
          changes: {
            title: 'Begin creating your website today',
            subtitle: 'Select all options that apply',
            questionHeader: 'What would you like to change?',
            answerNewPages: 'Add new pages',
            answerUpdatePages: 'Update existing pages',
            answerRedesign: 'Redesign'
          },
          purpose: {
            title: 'Begin creating your website today',
            subtitle: 'Select all options that apply',
            questionHeader: 'What is the primary purpose of your website?',
            answerBusinessPresentation: 'Business Presentation',
            answerSales: 'Online Sales',
            answerBookings: 'Online Bookings/Appointments'
          },
          content: {
            title: 'Begin creating your website today',
            subtitle: 'We will provide a quote - free of charge and without obligation',
            questionHeader: 'Do you already have the content (images and/or text) that you would like to add?'
          }
        },
        seo: {
          start: {
            title: 'Improve your SEO rankings',
            subtitle: 'We will provide a quote - free of charge and without obligation',
            questionHeader: 'Do you already have a website?'
          },
          status: {
            title: 'Improve your SEO rankings',
            subtitle: 'We will provide a quote - free of charge and without obligation',
            questionHeader: 'Have you already implemented SEO measures for your website?'
          },
          changes: {
            title: 'Improve your SEO rankings',
            subtitle: 'Select all options that apply',
            questionHeader: 'Which areas do you want to have optimized?',
            answerContent: 'Content',
            answerTechnical: 'Techinal',
            answerOffsite: 'Offsite'
          },
          purpose: {
            title: 'Improve your SEO rankings',
            subtitle: 'Select all options that apply',
            questionHeader: 'What do you want to achieve with these measures?',
            answerMoreVisitors: 'More Visitors',
            answerCompetition: 'Displace Competition',
            answerUsability: 'Improve Usability'
          }
        },
        webshop: {
          start: {
            title: 'Generate Traffic, Improve Performance, Increase Sales',
            subtitle: 'Select all options that apply',
            questionHeader: 'Do you already have an online store?'
          },
          changes: {
            title: 'Generate Traffic, Improve Performance, Increase Sales',
            subtitle: 'Select all options that apply',
            questionHeader: 'What measures do you want to have performed?',
            answerProducts: 'Adding Products or Pages',
            answerFunctions: 'Implementation of new Functionality',
            answerChangePlatform: 'Change of E-Commerce Platform'
          },
          purpose: {
            title: 'Generate Traffic, Improve Performance, Increase Sales',
            subtitle: 'Select all options that apply',
            questionHeader: 'What do you want to achieve with the measures?',
            answerSales: 'More Sales',
            answerSaveTime: 'Time Saving',
            answerUsability: 'Improve Usability'
          },
          content: {
            title: 'Generate Traffic, Improve Performance, Increase Sales',
            subtitle: 'Select all options that apply',
            questionHeader: 'Do you already have the content (product images and/or texts) for your online shop?'
          }
        },
        app: {
          start: {
            title: 'Modern, High Quality and Powerful App Solutions',
            subtitle: 'We will provide a quote - free of charge and without obligation',
            questionHeader: 'Do you already have an application?'
          },
          platforms: {
            title: 'Modern, High Quality and Powerful App Solutions',
            subtitle: 'Select all options that apply',
            questionHeader: 'Which platforms are you looking to build for?',
            answerWeb: 'Web',
            answerIOS: 'iOS',
            answerAndroid: 'Android'
          },
          purpose: {
            title: 'Modern, High Quality and Powerful App Solutions',
            subtitle: 'Select all options that apply',
            questionHeader: 'What is the primary purpose of your app?',
            answerGaming: 'Gaming',
            answerBusiness: 'Business',
            answerShopping: 'Shopping'
          },
          design: {
            title: 'Modern, High Quality and Powerful App Solutions',
            subtitle: 'Select all options that apply',
            questionHeader: 'Do you already have a design for your app?'
          }
        },
        sea: {
          start: {
            title: 'SEA for more clicks, visitors and sales',
            subtitle: 'We will provide a quote - free of charge and without obligation',
            questionHeader: 'Do you already have an advertising account?'
          },
          changes: {
            title: 'SEA for more clicks, visitors and sales ',
            subtitle: 'Select all options that apply',
            questionHeader: 'What measures do you want to have performed?',
            answerNewCampaigns: 'Create New Campaigns',
            answerOpitimise: 'Optimise Campaigns',
            answerTracking: 'Implement Tracking'
          },
          purpose: {
            title: 'SEA for more clicks, visitors and sales',
            subtitle: 'Select all options that apply',
            questionHeader: 'What kind of campaigns would you like to have implemented/optimised?',
            answerSearch: 'Search',
            answerDisplay: 'Display',
            answerShopping: 'Shopping'
          },
          content: {
            title: 'SEA for more clicks, visitors and sales',
            subtitle: 'Select all options that apply',
            questionHeader: 'Do you already have the content (images/videos/texts) for your ads?'
          }
        }
      }
    }
  },
  de: {
    client: {
      home: {
        welcome: 'Willkommen bei LeanLancer!',
        greeting: `Schön, dass Du an unserem Early Access Programm teilnimmst!!
        Wie Du bald feststellen wirst, sind die meisten Funktionen noch nicht verfügbar, wie z.B. Projekte oder Rechnungen.
        Du kannst jedoch bereits ein Profil erstellen, und wir werden uns mit Dir in Verbindung setzen, sobald wir einen Job für Dich haben - also hoffentlich sehr bald!`,
        next: 'Was passiert jetzt?',
        step1Title: '1. Profil ausfüllen',
        step1Content: 'Sobald die grundlegenden Informationen zu deinem Unternehmen ausgefüllt wurden, kannst Du beginnen deine Projekte zu beauftragen.',
        step2Title: '2. Angebot anfragen',
        step2Content: 'Im nächsten Schritt kannst Du ein unverbindliches Angebot anfragen. Wir führen Dich Deinen Kenntnissen entsprechend durch die Optionen und Dienstleistungen. Solltest Du mal einem Punkt nicht weiter wissen, steht Dir jederzeit einer unserer Mitarbeiter zur Seite.',
        step3Title: '3. Rücksprache und Kostenvoranschlag',
        step3Content: 'Nachdem Du das unverbindliche Angebot angefragt hat, wird sich einer unserer Sales-Mitarbeiter mit Dir in Verbindung setzen, um sicherzustellen, dass all Deine Wünsche bei der Durchführung berücksichtigt werden und es zu keinen Missverständnissen während des Projekts kommt. Auf Basis dessen, erstellen wir einen Kostenvoranschlag und schicken dir diesen per Mail. Solltest Du einverstanden sein, beginnt die Beauftragung.',
        step4Title: '4. Qualitätsmanagement',
        step4Content: 'Nachdem der Freelancer die Aufgaben beendet hat, unterziehen wir diese einer genauen Prüfung und gleichen diese mit der vereinbarten Leistung ab. So stellen wir eine optimale Qualität sicher, und können garantieren, dass Du genau das Endprodukt erhälst, das Du Dir vorgestellt hast.',
        step5Title: '5. Bezahlung',
        step5Content: 'Wenn die Leistungen in der vereinbarten Qualität erbracht wurden, erhalten Sie von uns eine Rechnung. Bitte beachten Sie, dass wir bei größeren Beträgen kleinere Teilrechnungen ausstellen. Damit wollen wir sicherstellen, dass unsere Freelancer pünktlich bezahlt werden. Das ist nicht nur fair, sondern erhöht auch deren Motivation und damit die Qualität unserer Dienstleistungen.'
      },
      signIn: {
        quote: '„Jemand, der mit einem Talent geboren wurde, wird seine größte Glückseligkeit erleben, wenn er dieses Talent benutzt. "',
        quoteAuthor: 'Johann Wolfgang von Goethe',
        quoteProfession: 'Deutcher Dichter',
        title: 'Anmelden',
        subline: 'mit Social-Media-Account',
        loginFacebook: 'Anmelden mit Facebook',
        loginGoogle: 'Anmelden mit Google',
        loginEmail: 'oder mit E-Mail-Adresse',
        submit: 'Jetzt anmelden',
        noAccount: 'Noch kein Konto?',
        signUp: 'Jetzt registrieren.',
        emailLabel: 'E-Mail-Adresse',
        passwordLabel: 'Passwort',
        forgotPassword: 'Passwort vorgessen?',
        passwordResetSent: 'Es wurde eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts gesendet.',
        passwordResetEmailMissing: 'Gib Deine E-Mail-Adresse ein, um das Passwort zurückzusetzen.'
      },
      signUp: {
        title: 'Neues Konto erstellen',
        subline: 'Mit Social Media registrieren',
        firstNameLabel: 'Vorname',
        lastNameLabel: 'Nachname',
        emailLabel: 'E-Mail-Adresse',
        passwordLabel: 'Passwort',
        tcRead: 'Ich habe die',
        tc: 'Nutzungsbedingungen',
        tcAfter: 'gelesen',
        submit: 'Jetzt registrieren',
        hasAccount: 'Hast du ein Konto?',
        signIn: 'Anmelden',
        google: 'Mit Google registrieren',
        facebook: 'Mit Facebook registrieren',
        signUpEmail: 'oder registriere Dich per E-Mail'
      },
      profile: {
        save: 'Speichern',
        savedAlert: 'Gespeichert!',
        general: { title: 'Allgemeine Informationen' },
        skills: {
          title: 'Fähigkeiten',
          subheader: 'Erstelle eine Liste mit Stichwörtern, die Deine relevanten Fähigkeiten beschreiben',
          placeholder: 'Gebe Deine Fähigkeiten ein (bspw. ReactJS oder MongoDB)'
        },
        biography: {
          title: 'Biografie',
          subheader: 'Erzähle etwas über Dich',
          placeholder: 'Schreibe ein paar Zeilen über Deinen Hintergrund und Deine Persönlichkeit'
        },
        languages: {
          title: 'Sprachen',
          subheader: 'Sprachen, in denen du arbeiten oder kommunizieren kannst',
          add: 'Weitere Sprache hinzufügen',
          proficieny: 'Level',
          native: 'Muttersprachler',
          fluent: 'Fließend',
          proficient: 'Gute Kenntnisse',
          basic: 'Grundkenntnisse'
        },
        cv: {
          title: 'Lebenslauf hochladen',
          subheader: 'Lade Deinen Lebenslauf zur Überprüfung, damit wir Deine Fähigkeiten validieren können'
        }
      },
      invoices: {
        noInvoices: 'Du hast noch keine Rechnungen',
        invoiceNumber: 'Rechnungsnummer',
        clientName: 'Kunde',
        issueDate: 'Ausstellungsdatum',
        dueDate: 'Fälligkeitsdatum',
        description: 'Beschreibung',
        quantity: 'Menge',
        unitPrice: 'Einzelpreis',
        subtotal: 'Zwischensumme',
        tax: 'Steuern',
        total: 'Gesamt',
        paymentDetails: 'Zahlungsdetails',
        businessName: 'Firmenname',
        address: 'Adresse',
        phone: 'Telefon',
        email: 'E-Mail',
        website: 'Website',
        bank: 'Bank'
      },
      jobOffers: {
        createJobOffer: 'Jobangebot erstellen',
        title: 'Titel',
        company: 'Unternehmen',
        description: 'Beschreibung',
        requirement: 'Anforderung',
        requirements: 'Anforderungen',
        active: 'Aktiv',
        addRequirement: 'Anforderung hinzufügen',
        budget: 'Budget',
        duration: 'Dauer',
        contact: 'Kontakt',
        contactName: 'Kontaktname',
        contactEmail: 'Kontakt E-Mail',
        contactPhone: 'Kontakt Telefon',
        editJobOffer: 'Jobangebot bearbeiten',
        name: 'Name',
        email: 'E-Mail',
        phone: 'Telefon',
        remove: 'Entfernen',
        noOffers: 'Du hast noch keine Jobangebote erstellt',
        addJobOffer: 'Jobangebot erstellen'
      },
      settings: {
        authentication: {
          title: 'Authentifizierung',
          subtitle: 'Von hier aus kannst Du Anmeldemethoden hinzufügen und bearbeiten',
          passwordMismatch: 'Das eingegebene Passwort stimmt nicht überein.',
          linkGoogle: 'Google-Konto verknüpfen',
          googleLinked: 'Google-Konto verknüpft',
          googleLinkedSuccess: 'Google-Konto erfolgreich verknüpft',
          linkFacebook: 'Facebook-Konto verknüpfen',
          facebookLinked: 'Facebook-Konto verknüpft',
          facebookLinkedSuccess: 'Facebook-Konto erfolgreich verknüpft',
          reauthDialogTitle: 'Melde Dich aus Sicherheitsgründen erneut an',
          reauthWithPassword: 'Mit Passwort anmelden',
          reauthSuccess: 'Wieder erfolgreich angemeldet. Du kannst jetzt Konten verknüpfen',
          setPassword: 'Leg ein Passwort für Dein Konto fest',
          repeatPassword: 'Wiederhole das Passwort',
          setPasswordSubmit: 'Passwort festlegen',
          resetPasswordSubmit: 'Passwort zurücksetzen'
        }
      },
      projects: {
        projectMembers: 'Projekt-Mitglieder',
        skills: 'Fertigkeiten',
        addTask: 'Aufgabe hinzufügen',
        editTask: 'Aufgabe bearbeiten',
        addTaskSuccess: 'Aufgabe erfolgreich hinzugefügt',
        addTaskError: 'Aufgabe konnte nicht hinzugefügt werden',
        taskUpdated: 'Aufgabe erfolgreich aktualisiert',
        taskUpdateError: 'Aufgabe konnte nicht aktualisiert werden'
      },
      leads: {
        start: {
          title: 'Wonach suchst Du?',
          selectServices: 'Ich weiß, welche Leistungen ich will!',
          selectGoals: 'Ich weiß, welches Ergebnisse ich will!'
        },
        goals: {
          title: 'Was sind Deine Ziele?',
          subtitle: 'Du kannst mehrere Optionen wählen.',
          brandAwareness: 'Marken&shy;bekanntheit',
          reach: 'Reichweite',
          webTraffic: 'Webseiten&shy;besuche',
          engagement: 'Interaktionen',
          appInstalls: 'App-Installierungen',
          leadGeneration: 'Lead-Generierung',
          messages: 'Nachrichten',
          sales: 'Verkäufe',
          storeVisits: 'Ladenbesuche',
          other: 'Andere'
        },
        services: {
          title: 'Wähle deine Dienstleistungen',
          subtitle: 'Du kannst mehrere Optionen auswählen',
          onlineMarketing: 'Online Marketing',
          development: 'Development',
          graphicDesign: 'Web Design',
          copywriting: 'Texterstellung',
          translations: 'Übersetzungen',
          other: 'Andere'
        },
        describeRequest: {
          title: 'Beschreibe Dein Anliegen',
          jobTitle: 'Job Titel',
          jobDescription: 'Job-Beschreibung'
        },
        almostThere: {
          title: 'Fast geschafft',
          projectPeriod: 'Projektzeitraum',
          projectStart: 'Start',
          projectEnd: 'Ende',
          approxBudget: 'Budgetvorstellung',
          additionalInfo: 'Möchtest Du uns noch etwas mitteilen?'
        },
        verification: {
          title: 'Überprüfung Deiner Anfrage',
          message: 'Super! Wir können Dein Projekt umsetzen! Fahre fort, um eine kostenfreie Beratung zu erhalten.'
        },
        contact: {
          title: 'Kontakt Details',
          trustBox1: 'Pool verifizierter Freelancer',
          trustBox2: 'Professionelles Projektmanagement',
          trustBox3: 'Hohe Qualität durch standardisierte Prozesse',
          trustBox4: 'Bezahlung bei Erreichung von Meilensteinen',
          trustBox5: 'Alle Projekte mit Versicherungsschutz'
        },
        thankYou: {
          title: 'Wie geht es weiter?',
          subtitle: 'Wir haben Deine Anfrage erhalten und ein zuständiger Projektmanager wird sie so schnell wie möglich prüfen',
          step1: {
            title: '1. Kontakt',
            body: 'Wenn Sie Ihre Telefonnummer angegeben haben, rufen wir Sie innerhalb der nächsten 3 Arbeitstage an. Andernfalls senden wir Ihnen eine E-Mail, um einen Termin zu vereinbaren und Ihre Anfrage zu überprüfen.'
          },
          step2: {
            title: '2. Auswahl der Freelancer',
            body: 'Sobald wir alle Aufgaben definiert haben, wählen wir den/die passenden Freelancer für Dein Projekt aus. Die Auswahl erfolgt ausschließlich nach den Fähigkeiten.'
          },
          step3: {
            title: '3. Vertrags&shy;unterzeichnung',
            body: 'Nachdem wir Dein Team zusammengestellt haben, senden wir Dir einen Vertrag zur Unterschrift zu. Der Vertrag enthält eine Übersicht über alle Aufgaben und Preise.'
          },
          step4: {
            title: '4. Projekt-Kickoff',
            body: 'Nach Erhalt des unterzeichneten Vertrags beginnen wir mit der Arbeit an Deinem Projekt. Während der gesamten Laufzeit steht Dir ein qualifizierter Projektmanager zur Seite.'
          },
          backToHome: 'zurück zur Startseite',
          createAccount: 'Konto erstellen'
        },
        website: {
          start: {
            title: 'Beginne noch heute mit der Erstellung Deiner Website',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du schon eine Webseite?'
          },
          changes: {
            title: 'Beginne noch heute mit der Erstellung Deiner Website',
            subtitle: 'Wähle alle Optionen, die zutreffen',
            questionHeader: 'Was würdest Du gerne ändern?',
            answerNewPages: 'Neue Seiten hinzufügen',
            answerUpdatePages: 'Vorhandene Seiten überarbeiten',
            answerRedesign: 'Umgestaltung'
          },
          purpose: {
            title: 'Beginne noch heute mit der Erstellung Deiner Website',
            subtitle: 'Wähle alle Optionen, die zutreffen',
            questionHeader: 'Was ist der primäre Zweck Deiner Webseite?',
            answerBusinessPresentation: 'Unternehmenspräsentation',
            answerSales: 'Online-Verkäufe',
            answerBookings: 'Online-Buchungen/Terminvereinbarungen'
          },
          content: {
            title: 'Beginne noch heute mit der Erstellung Deiner Website',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du bereits den Inhalt (Bilder und/oder Text), den Du hinzufügen möchtest?'
          }
        },
        seo: {
          start: {
            title: 'Verbessere Deine SEO-Rankings',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du bereits eine Webseite?'
          },
          status: {
            title: 'Verbessere Deine SEO-Rankings',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du bereits SEO-Maßnahmen für deine Webseite durchgeführt?'
          },
          changes: {
            title: 'Verbessere Deine SEO-Rankings',
            subtitle: 'Wähle alle Optionen, die zutreffen',
            questionHeader: 'Welche Bereiche möchtest Du optimieren lassen?',
            answerContent: 'Content',
            answerTechnical: 'Technik',
            answerOffsite: 'Offsite'
          },
          purpose: {
            title: 'Verbessere Deine SEO-Rankings',
            subtitle: 'Wähle alle Optionen, die zutreffen',
            questionHeader: 'Was möchtest Du mit den Maßnahmen erreichen?',
            answerMoreVisitors: 'Mehr Besucher',
            answerCompetition: 'Konkurrenz verdrängen',
            answerUsability: 'Nutzerfreundlichkeit steigern'
          }
        },
        webshop: {
          start: {
            title: 'Traffic generieren, Leistung verbessern, Umsatz steigern',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du bereits einen Online-Shop?'
          },
          changes: {
            title: 'Traffic generieren, Leistung verbessern, Umsatz steigern',
            subtitle: 'Wähle alle Optionen, die zutreffen',
            questionHeader: 'Welche Maßnahmen möchtest du durchführen?',
            answerProducts: 'Hinzufügen von Produkten oder Seiten',
            answerFunctions: 'Implementierung neuer Funktionen',
            answerChangePlatform: 'Wechsel der E-Commerce-Plattform'
          },
          purpose: {
            title: 'Traffic generieren, Leistung verbessern, Umsatz steigern',
            subtitle: 'Wähle alle Optionen, die zutreffen',
            questionHeader: 'Was möchtest Du mit den Maßnahmen erreichen?',
            answerSales: 'Mehr Umsatz',
            answerSaveTime: 'Zeitersparnis',
            answerUsability: 'Nutzerfreundlichkeit steigern'
          },
          content: {
            title: 'Traffic generieren, Leistung verbessern, Umsatz steigern',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du bereits die Inhalte (Produktbilder und/oder Texte) für deinen Online-Shop?'
          }
        },
        app: {
          start: {
            title: 'Moderne und leistungsstarke Apps in bester Qualität',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du bereits eine App?'
          },
          platforms: {
            title: 'Moderne und leistungsstarke Apps in bester Qualität',
            subtitle: 'Wähle alle Optionen, die zutreffen',
            questionHeader: 'Für welche Plattformen willst Du bauen?',
            answerWeb: 'Web',
            answerIOS: 'iOS',
            answerAndroid: 'Android'
          },
          purpose: {
            title: 'Moderne und leistungsstarke Apps in bester Qualität',
            subtitle: 'Wähle alle Optionen, die zutreffen',
            questionHeader: 'Was ist der primäre Zweck Deiner App?',
            answerGaming: 'Gaming',
            answerBusiness: 'Geschäftlich',
            answerShopping: 'Shopping'
          },
          design: {
            title: 'Moderne und leistungsstarke Apps in bester Qualität',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du bereits ein Design für Deiner App?'
          }
        },
        sea: {
          start: {
            title: 'SEA für mehr Klicks, Besucher und Umsatz ',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du bereits einen Werbe-Account?'
          },
          changes: {
            title: 'SEA für mehr Klicks, Besucher und Umsatz ',
            subtitle: 'Wähle alle Optionen, die zutreffen',
            questionHeader: 'Welche Maßnahmen möchtest Du durchführen?',
            answerNewCampaigns: 'Neue Kampagnen erstellen',
            answerOpitimise: 'Kampagnen optimieren',
            answerTracking: 'Tracking implementieren'
          },
          purpose: {
            title: 'SEA für mehr Klicks, Besucher und Umsatz ',
            subtitle: 'Wähle alle Optionen',
            questionHeader: 'Welche Art von Kampagnen möchtest Du implementiert/optimiert haben?',
            answerSearch: 'Suchkampagnen',
            answerDisplay: 'Displaykampagnen',
            answerShopping: 'Shopping-Kampagnen'
          },
          content: {
            title: 'SEA für mehr Klicks, Besucher und Umsatz ',
            subtitle: 'Wir erstellen ein Angebot - kostenlos und unverbindlich',
            questionHeader: 'Hast Du schon die Inhalte (Bilder oder Videos und Texte) für Deine Anzeigen?'
          }
        }
      }
    }
  }
}
export default resources
