import { Flex } from '@leanlancer/ui'
import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import useLeadData from '../../../hooks/useLeadData'
import AppInstallImg from '../icons/app-install.svg'
import { useTranslation } from 'react-i18next'

import BrandImg from '../icons/brand.svg'
import EngagementImg from '../icons/engagement.svg'
import LeadsImg from '../icons/leads.svg'
import MessagesImg from '../icons/messages.svg'
import OtherImg from '../icons/other.svg'
import ReachImg from '../icons/reach.svg'
import SalesImg from '../icons/sales.svg'
import StoreImg from '../icons/store.svg'
import WebTrafficImg from '../icons/web-traffic.svg'

const Goal = ({ goNext, ...props }) => {
  const { t } = useTranslation('client')

  const [leadSnap, leadData] = useLeadData()
  const [selected, setSelected] = useState(leadData?.goals || [])

  const handleSelectItem = (item) => (e) => {
    e.preventDefault()
    if (selected.includes(item)) {
      setSelected(selected.filter(i => i !== item))
    } else {
      setSelected([...selected, item])
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    leadSnap.ref.update({
      type: 'default',
      goals: selected
    })
    goNext('describe-request')
  }

  const Actions = () => (
    <Flex dir='rtl'>
      <Button
        disabled={selected.length < 1}
        variant='outlined'
        color='primary'
        onClick={handleSubmit}
      >{t('common:inputs.next')}
      </Button>
    </Flex>
  )

  return (
    <>
      <FunnelQuestion
        title={t('leads.goals.title')}
        subtitle={t('leads.goals.subtitle')}
        progress={20}
        actions={<Actions />}
      >
        <FunnelSelectCardGrid
          cards={[
            {
              onSelect: handleSelectItem('brand-awareness'),
              selected: selected.includes('brand-awareness'),
              title: t('leads.goals.brandAwareness'),
              imgSrc: BrandImg
            },
            {
              onSelect: handleSelectItem('reach'),
              selected: selected.includes('reach'),
              title: t('leads.goals.reach'),
              imgSrc: ReachImg
            },
            {
              onSelect: handleSelectItem('web-traffic'),
              selected: selected.includes('web-traffic'),
              title: t('leads.goals.webTraffic'),
              imgSrc: WebTrafficImg
            },
            {
              onSelect: handleSelectItem('engagement'),
              selected: selected.includes('engagement'),
              title: t('leads.goals.engagement'),
              imgSrc: EngagementImg
            },
            {
              onSelect: handleSelectItem('app-installs'),
              selected: selected.includes('app-installs'),
              title: t('leads.goals.appInstalls'),
              imgSrc: AppInstallImg
            },
            {
              onSelect: handleSelectItem('lead-generation'),
              selected: selected.includes('lead-generation'),
              title: t('leads.goals.leadGeneration'),
              imgSrc: LeadsImg
            },
            {
              onSelect: handleSelectItem('messages'),
              selected: selected.includes('messages'),
              title: t('leads.goals.messages'),
              imgSrc: MessagesImg
            },
            {
              onSelect: handleSelectItem('sales'),
              selected: selected.includes('sales'),
              title: t('leads.goals.sales'),
              imgSrc: SalesImg
            },
            {
              onSelect: handleSelectItem('store-visits'),
              selected: selected.includes('store-visits'),
              title: t('leads.goals.storeVisits'),
              imgSrc: StoreImg
            },
            {
              onSelect: handleSelectItem('other'),
              selected: selected.includes('other'),
              title: t('leads.goals.other'),
              imgSrc: OtherImg
            }

          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default Goal
