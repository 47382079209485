import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import { useParams } from 'react-router-dom'
import JobOfferDetailView from './JobOfferDetailView'
import JobOffersListView from './JobOffersListView'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const JobOffers = ({ className, ...props }) => {
  const classes = useStyles()
  const params = useParams()
  console.log('🚀 ~ JobOffers ~ params:', params)

  return (
    <div className={clsx(classes.root, className)}>
      {params?.id ? <JobOfferDetailView id={params.id} /> : <JobOffersListView />}
    </div>
  )
}

export default JobOffers
