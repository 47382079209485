import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import KanbanTaskCard from './KanbanTaskCard'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  kanbanContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%', // Updated width to 100%
    padding: theme.spacing(2)
  },
  columnHeader: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold
  },
  taskCard: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  overdueDate: {
    color: theme.palette.error.main
  },
  dueSoonDate: {
    color: theme.palette.warning.main
  }
}))

const KanbanView = ({ tasks }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const STATUS_LABELS = {
    pending: t('common:projects.statuses.pending'),
    in_progress: t('common:projects.statuses.inProgress'),
    qa: t('common:projects.statuses.qa'),
    completed: t('common:projects.statuses.completed'),
    cancelled: t('common:projects.statuses.cancelled')
  }

  const updateTaskStatus = async (taskId, newStatus) => {
    try {
      const db = firebase.firestore()
      const taskRef = db.collection('project_tasks').doc(taskId)

      // Update the task's status field
      await taskRef.update({
        status: newStatus
      })

      console.log('Task status updated successfully')
    } catch (error) {
      console.error('Error updating task status:', error)
    }
  }

  const handleDragEnd = (result) => {
    const { draggableId, source, destination } = result

    // If the task was not dropped on a valid destination, do nothing
    if (!destination) {
      return
    }

    // If the task was dropped in the same position, do nothing
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    const taskId = draggableId
    const newStatus = destination.droppableId

    // Update the task status in Firebase Firestore
    updateTaskStatus(taskId, newStatus)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={classes.kanbanContainer}>
        {Object.keys(STATUS_LABELS).map((status) => (
          <Droppable droppableId={status} key={status}>
            {(provided) => (
              <div
                className={classes.columnContainer}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Typography variant='h6' className={classes.columnHeader}>
                  {STATUS_LABELS[status]}
                </Typography>
                {tasks
                  .filter((task) => task.status === status)
                  .map((task, index) => (
                    <Draggable draggableId={task.id} index={index} key={task.id}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <KanbanTaskCard task={task} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  )
}

export default KanbanView
