import { AuthUserContext } from '@leanlancer/common/dist/contexts'
import { Flex, LinkButton, EmblemFrame } from '@leanlancer/ui'
import { FunnelQuestion } from '@leanlancer/ui/dist/modules/LeadFunnel'
import { ContractSigning, FreelancerSelect, Phone, ProjectKickoff } from '@leanlancer/ui/dist/icons'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  contentTitle: {
    fontSize: 20,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))

const Content = ({ title, body }) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant='h3' align='center' className={classes.contentTitle} dangerouslySetInnerHTML={{ __html: title }} />
      <Typography align='center'>
        {body}
      </Typography>
    </>
  )
}
const Actions = () => {
  const authUser = useContext(AuthUserContext)
  const { t } = useTranslation('client')

  if (authUser.isAnonymous) {
    return (
      <Flex center>
        <LinkButton
          to='/sign-up'
          variant='contained'
          color='primary'
        >{t('leads.thankYou.createAccount')}
        </LinkButton>
      </Flex>
    )
  }

  return (
    <Flex center>
      <LinkButton
        to='/'
        variant='contained'
        color='primary'
      >{t('leads.thankYou.backToHome')}
      </LinkButton>
    </Flex>
  )
}

const ThankYou = ({ goNext, ...props }) => {
  // const classes = useStyles()
  const { t } = useTranslation('client')

  return (
    <>
      <FunnelQuestion
        title={t('leads.thankYou.title')}
        subtitle={t('leads.thankYou.subtitle')}
        progress={100}
        hideProgress
        actions={<Actions />}
      >
        <Grid container spacing={2} alignItems='stretch'>
          <Grid item xs={12} md={6} lg={3}>
            <EmblemFrame icon={Phone}>
              <Content title={t('leads.thankYou.step1.title')} body={t('leads.thankYou.step1.body')} />
            </EmblemFrame>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <EmblemFrame icon={FreelancerSelect}>
              <Content title={t('leads.thankYou.step2.title')} body={t('leads.thankYou.step2.body')} />
            </EmblemFrame>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <EmblemFrame icon={ContractSigning}>
              <Content title={t('leads.thankYou.step3.title')} body={t('leads.thankYou.step3.body')} />
            </EmblemFrame>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <EmblemFrame icon={ProjectKickoff}>
              <Content title={t('leads.thankYou.step4.title')} body={t('leads.thankYou.step4.body')} />
            </EmblemFrame>
          </Grid>
        </Grid>
      </FunnelQuestion>
    </>
  )
}

export default ThankYou
