import { Flex, Loader } from '@leanlancer/ui'
import CheckedIcon from '@leanlancer/ui/dist/icons/Checked'
import { FunnelQuestion } from '@leanlancer/ui/dist/modules/LeadFunnel'
import { Box, Button, SvgIcon, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Verification = ({ goNext }) => {
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation('client')

  useEffect(() => {
    return setTimeout(() => {
      setLoading(false)
    }, 2000)
  })
  const handleContinue = () => goNext('contact')

  return (
    <FunnelQuestion
      title={t('leads.verification.title')}
      hideProgress
    >
      <Flex center dir='ttb'>
        {loading
          ? <Loader />
          : (
            <>
              <Box py={2}>
                <SvgIcon component={CheckedIcon} style={{ height: 60, width: 60 }} color='secondary' />
              </Box>
              <Box py={1}>
                <Typography variant='h4'>{t('leads.verification.message')}</Typography>
              </Box>
              <Box pt={3}>
                <Button variant='contained' color='primary' onClick={handleContinue}>{t('common:inputs.continue')}</Button>
              </Box>
            </>
            )}
      </Flex>
    </FunnelQuestion>
  )
}

export default Verification
