"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _Minimal.default;
  }
});

var _Minimal = _interopRequireDefault(require("./Minimal"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }