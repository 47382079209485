"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _atoms = require("./atoms");

Object.keys(_atoms).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _atoms[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _atoms[key];
    }
  });
});

var _molecules = require("./molecules");

Object.keys(_molecules).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _molecules[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _molecules[key];
    }
  });
});

var _organisms = require("./organisms");

Object.keys(_organisms).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _organisms[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _organisms[key];
    }
  });
});