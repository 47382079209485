import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import CheckIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'
import EuroIcon from '@material-ui/icons/Euro'
import PeopleIcon from '@material-ui/icons/People'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    border: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 8,
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0)
  },
  iconRoot: {
    // backgroundImage: 'linear-gradient(136deg, #009ccb 800%, #1DCDFE 80%, #1DCDFE 0%)',
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  }
  // iconActive: {
  //   backgroundImage: 'linear-gradient(136deg, rgb(0, 191, 255) 0%, rgb(0,64,87) 50%, rgb(40,35,135) 100%)',
  //   border: '4px solid #1dcdfe',
  //   boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  // }
}))

const ColouredIcon = ({ children }) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(classes.iconRoot)}
    >
      {children}
    </div>
  )
}

const ClientSteps = () => {
  const { t } = useTranslation('client')
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>

        <Grid item xs={2} md={1}>
          <ColouredIcon><EditIcon /></ColouredIcon>
        </Grid>
        <Grid item xs={10} md={11}>
          <Typography variant='h3' gutterBottom>{t('home.step1Title')}</Typography>
          <Typography>
            {t('home.step1Content')}
          </Typography>
        </Grid>

        <Grid item xs={2} md={1}>
          <ColouredIcon><AddShoppingCartIcon /></ColouredIcon>
        </Grid>

        <Grid item xs={10} md={11}>
          <Typography variant='h3' gutterBottom> {t('home.step2Title')}</Typography>
          <Typography>{t('home.step2Content')}</Typography>
        </Grid>

        <Grid item xs={2} md={1}>
          <ColouredIcon><PeopleIcon /></ColouredIcon>
        </Grid>
        <Grid item xs={10} md={11}>
          <Typography variant='h3' gutterBottom>{t('home.step3Title')}</Typography>
          <Typography>{t('home.step3Content')}</Typography>
        </Grid>

        <Grid item xs={2} md={1}>
          <ColouredIcon><CheckIcon /></ColouredIcon>
        </Grid>
        <Grid item xs={10} md={11}>
          <Typography variant='h3' gutterBottom>{t('home.step4Title')}</Typography>
          <Typography>{t('home.step4Content')}</Typography>
        </Grid>

        <Grid item xs={2} md={1}>
          <ColouredIcon><EuroIcon /></ColouredIcon>
        </Grid>
        <Grid item xs={10} md={11}>
          <Typography variant='h3' gutterBottom>{t('home.step5Title')}</Typography>
          <Typography>{t('home.step5Content')}</Typography>
        </Grid>

      </Grid>
    </div>
  )
}

export default ClientSteps
