"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var profileLanguages = [{
  label: 'Mandarin Chinese',
  id: 'mandarin'
}, {
  label: 'Spanish',
  id: 'spanish'
}, {
  label: 'English',
  id: 'english'
}, {
  label: 'Hindi',
  id: 'hindi'
}, {
  label: 'Portuguese',
  id: 'portugese'
}, {
  label: 'Russian',
  id: 'russian'
}, {
  label: 'Japanese',
  id: 'japanese'
}, {
  label: 'Turkish',
  id: 'turkish'
}, {
  label: 'Korean',
  id: 'korean'
}, {
  label: 'French',
  id: 'french'
}, {
  label: 'German',
  id: 'german'
}, {
  label: 'Vietnamese',
  id: 'vietnamese'
}, {
  label: 'Italian',
  id: 'italian'
}, {
  label: 'Arabic',
  id: 'arabic'
}, {
  label: 'Indonesian',
  id: 'indonesian'
}, {
  label: 'Polish',
  id: 'polish'
}, {
  label: 'Ukrainian',
  id: 'ukranian'
}, {
  label: 'Romanian',
  id: 'romanian'
}, {
  label: 'Dutch',
  id: 'dutch'
}, {
  label: 'Thai',
  id: 'thai'
}, {
  label: 'Malaysian',
  id: 'malysian'
}, {
  label: 'Greek',
  id: 'greek'
}, {
  label: 'Hungarian',
  id: 'hungarian'
}, {
  label: 'Czech',
  id: 'czech'
}];
var _default = profileLanguages;
exports["default"] = _default;