import { useSnackbar, AuthUserContext } from '@leanlancer/common'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useContext, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReauthenticateDialog from './ReauthenticateDialog'

const functions = firebase.app().functions('europe-west1')
const sendPasswordResetTemplate = functions.httpsCallable('auth-sendPasswordReset')

const useStyles = makeStyles(theme => ({
  root: {},
  loginItem: {
    margin: theme.spacing(2, 0)
  }
}))

const formReducer = (state, { name, value }) => {
  return { ...state, [name]: value }
}

const AuthenticationSettings = ({ className, ...rest }) => {
  const classes = useStyles()
  const authUser = useContext(AuthUserContext)
  const { t } = useTranslation('client')
  const snackbar = useSnackbar()
  const [formState, dispatch] = useReducer(formReducer, {
    password: '',
    passwordRepeat: ''
  })

  const [reauthDialogOpen, setReauthDialogOpen] = useState(false)
  const [passwordError, setPasswordError] = useState(null)
  const hasPasswordProvider = !!authUser?.providerData?.find(({ providerId }) => providerId === 'password')
  const hasGoogleProvider = !!authUser?.providerData?.find(({ providerId }) => providerId === 'google.com')
  const hasFacebookProvider = !!authUser?.providerData?.find(({ providerId }) => providerId === 'facebook.com')

  const handlePasswordReset = () => {
    sendPasswordResetTemplate({
      redirectUrl: window.location.href
    }).then(() => {
      snackbar({ type: 'open', message: t('client:signIn.passwordResetSent') })
    }).catch((err) => {
      snackbar({ type: 'open', message: err.message, severity: 'error' })
    })
  }

  const formChange = (event) => {
    setPasswordError(null)
    dispatch({ name: event.target.name, value: event.target.value })
  }

  const handleSetPassword = async () => {
    setPasswordError(null)
    if (formState.password !== formState.passwordRepeat) {
      return setPasswordError(t('settings.authentication.passwordMismatch'))
    }

    try {
      const credential = firebase.auth.EmailAuthProvider.credential(authUser.email, formState.password)
      await authUser.linkWithCredential(credential)
      snackbar({ type: 'open', message: 'Password set!' })
    } catch (error) {
      console.error(error)
      if (error.code === 'auth/requires-recent-login' || error.code === 'auth/invalid-user-token') {
        setReauthDialogOpen(true)
      } else {
        snackbar({ type: 'open', severity: 'error', message: error.message })
      }
    }
  }

  const handleLinkGoogleAccount = async () => {
    const googleProvider = new firebase.auth.GoogleAuthProvider()
    try {
      await authUser.linkWithPopup(googleProvider)
      snackbar({ type: 'open', message: t('settings.authentication.googleLinkedSuccess') })
    } catch (error) {
      snackbar({ type: 'open', severity: 'error', message: error.message })
    }
  }

  const handleLinkFacebookAccount = async () => {
    const facebookProvider = new firebase.auth.FacebookAuthProvider()
    try {
      await authUser.linkWithPopup(facebookProvider)
      snackbar({ type: 'open', message: t('settings.authentication.facebookLinkedSuccess') })
    } catch (error) {
      snackbar({ type: 'open', severity: 'error', message: error.message })
    }
  }

  const handleReauthError = (error) => {
    setReauthDialogOpen(false)
    snackbar({ type: 'open', severity: 'error', message: error?.message })
  }

  return (
    <>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          title={t('settings.authentication.title')}
          subheader={t('settings.authentication.subtitle')}
        />
        <Divider />
        <CardContent>

          <div className={classes.loginItem}>
            <Typography variant='body2' gutterBottom>{t('common:inputs.password')}</Typography>
            {hasPasswordProvider
              ? (
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={handlePasswordReset}
                >
                  {t('settings.authentication.resetPasswordSubmit')}
                </Button>
                )
              : (
                <>
                  <Typography variant='body1' gutterBottom>{t('settings.authentication.setPassword')}</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                      <TextField
                        fullWidth
                        variant='outlined'
                        label={t('common:inputs.password')}
                        name='password'
                        type='password'
                        value={formState.password}
                        onChange={formChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <TextField
                        fullWidth
                        variant='outlined'
                        label={t('settings.authentication.repeatPassword')}
                        type='password'
                        name='passwordRepeat'
                        value={formState.passwordRepeat}
                        onChange={formChange}
                      />
                    </Grid>
                    {passwordError && (
                      <Grid item xs={12}>
                        <FormHelperText error>
                          {passwordError}
                        </FormHelperText>
                      </Grid>
                    )}
                    <Grid item xs={12} md={7}>
                      <Button
                        variant='outlined'
                        onClick={handleSetPassword}
                      >
                        {t('settings.authentication.setPasswordSubmit')}
                      </Button>
                    </Grid>
                  </Grid>
                </>
                )}
          </div>
          <div className={classes.loginItem}>
            <Typography variant='body2'>Google</Typography>
            <Button
              color='primary'
              variant='outlined'
              disabled={hasGoogleProvider}
              onClick={handleLinkGoogleAccount}
            >
              {hasGoogleProvider ? t('settings.authentication.googleLinked') : t('settings.authentication.linkGoogle')}
            </Button>
          </div>
          <div className={classes.loginItem}>
            <Typography variant='body2'>Facebook</Typography>
            <Button
              color='primary'
              variant='outlined'
              disabled={hasFacebookProvider}
              onClick={handleLinkFacebookAccount}
            >
              {hasFacebookProvider ? t('settings.authentication.facebookLinked') : t('settings.authentication.linkFacebook')}
            </Button>
          </div>
        </CardContent>
        <Divider />
      </Card>
      <ReauthenticateDialog
        open={reauthDialogOpen}
        handleClose={() => setReauthDialogOpen(false)}
        handleError={handleReauthError}
        handleSignIn={() => {
          snackbar({ type: 'open', message: t('settings.authentication.reauthSuccess') })
          setReauthDialogOpen(false)
        }}
      />
    </>
  )
}

AuthenticationSettings.propTypes = {
  className: PropTypes.string
}

export default AuthenticationSettings
