import { Button, Grid, TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useContext } from 'react'
import { AuthUserContext } from '@leanlancer/common'
import { GoogleSignInButton, FacebookSignInButton } from '@leanlancer/ui'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { required, email } from '../../common/validators'
import { isFunction } from 'lodash'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(8)
  },
  textField: {
    margin: theme.spacing(1, 0)
  },
  cancelButton: {
    float: 'right',
    marginTop: theme.spacing(2)
  }
}))

const PasswordForm = ({ onSignIn, onError }) => {
  const classes = useStyles()
  const { t } = useTranslation('client')
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = async ({ email, password }) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
      if (isFunction(onSignIn)) {
        onSignIn()
      }
    } catch (error) {
      console.error(error)
      if (isFunction(onError)) {
        onError(error)
      }
    }
  }
  return (
    <>
      <Typography variant='h6'>{t('settings.authentication.reauthWithPassword')}</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              inputRef={register(email)}
              className={classes.textField}
              helperText={errors?.email?.message}
              error={!!errors?.email}
              fullWidth
              label={t('signIn.emailLabel')}
              name='email'
              type='text'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              inputRef={register(required)}
              fullWidth
              label={t('signIn.passwordLabel')}
              name='password'
              helperText={errors?.password?.message}
              error={!!errors?.password}
              type='password'
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant='outlined' color='primary' type='submit'>
              {t('signIn.submit')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ReauthenticateDialog = ({ open, handleClose, handleError, handleSignIn }) => {
  const classes = useStyles()
  const authUser = useContext(AuthUserContext)
  const providers = authUser?.providerData?.map(({ providerId }) => providerId)
  const { t } = useTranslation('client')

  const getProviderSignIn = (provider) => {
    switch (provider) {
      case 'google.com':
        return <GoogleSignInButton onSignIn={handleSignIn} onError={handleError} label={t('signIn.loginGoogle')} />
      case 'facebook.com':
        return <FacebookSignInButton onSignIn={handleSignIn} onError={handleError} label={t('signIn.loginFacebook')} />
      case 'password':
        return <PasswordForm onSignIn={handleSignIn} onError={handleError} />
      default:
        throw new Error('No Login method for provider')
    }
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <div className={classes.root}>
        <Typography variant='h2' gutterBottom>{t('settings.authentication.reauthDialogTitle')}</Typography>
        <Grid spacing={4} container>
          {providers?.map((provider) => {
            return (
              <Grid key={provider} item xs={12}>
                {getProviderSignIn(provider)}
              </Grid>
            )
          })}
        </Grid>
        <Button className={classes.cancelButton} onClick={handleClose}>{t('common:inputs.cancel')}</Button>
      </div>
    </Dialog>
  )
}

ReauthenticateDialog.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSignIn: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export default ReauthenticateDialog
