import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ClientSteps from './ClientSteps'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    minHeight: '100%'
  }
}))

const Home = () => {
  const { t } = useTranslation('client')
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Typography variant='h1'>{t('home.welcome')}</Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant='h3'>
            {t('home.next')}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <ClientSteps />
        </Grid>
      </Grid>
    </div>
  )
}

export default Home
