import { useSnackbar } from '@leanlancer/common'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  makeStyles
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2) // Add vertical spacing between fields
  }
}))

const AddTaskDialog = ({ open, onClose, projectId, projectMilestones }) => {
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [status, setStatus] = useState('pending')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [milestoneId, setMilestoneId] = useState('')
  const { t } = useTranslation(['common', 'client'])

  const snackbar = useSnackbar()

  const handleTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
  }

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value)
  }

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value)
  }

  const handleMilestoneChange = (event) => {
    const selectedMilestoneId = event.target.value
    setMilestoneId(selectedMilestoneId === '' ? '' : selectedMilestoneId)
  }

  const handleSubmitTask = () => {
    const taskData = {
      title,
      status,
      startDate,
      endDate,
      milestoneId,
      projectId
    }

    const firestore = firebase.firestore()
    firestore
      .collection('project_tasks')
      .add(taskData)
      .then((docRef) => {
        console.log(`Task added successfully! ID: ${docRef.id}`)
        snackbar({
          type: 'open',
          severity: 'success',
          message: t('client:projects.addTaskSuccess')
        })
      })
      .catch((error) => {
        console.error('Error adding task:', error)
        snackbar({
          type: 'open',
          severity: 'error',
          message: t('client:projects.addTaskError')
        })
      })

    setTitle('')
    setStatus('pending')
    setStartDate('')
    setEndDate('')
    setMilestoneId('')

    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('client:projects.addTask')}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth className={classes.formControl}>
          <TextField label='Title' value={title} onChange={handleTitleChange} />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <Select value={status} onChange={handleStatusChange} displayEmpty>
            <MenuItem value='pending'>{t('common:projects.statuses.pending')}</MenuItem>
            <MenuItem value='in_progress'>{t('common:projects.statuses.inProgress')}</MenuItem>
            <MenuItem value='qa'>{t('common:projects.statuses.qa')}</MenuItem>
            <MenuItem value='completed'>{t('common:projects.statuses.completed')}</MenuItem>
            <MenuItem value='cancelled'>{t('common:projects.statuses.cancelled')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            label={t('common:projects.startDate')}
            type='date'
            value={startDate}
            onChange={handleStartDateChange}
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            label={t('common:projects.endDate')}
            type='date'
            value={endDate}
            onChange={handleEndDateChange}
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <Select value={milestoneId} onChange={handleMilestoneChange} displayEmpty>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {projectMilestones.map((milestone) => (
              <MenuItem key={milestone.id} value={milestone.id}>
                {milestone.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {t('common:inputs.cancel')}
        </Button>
        <Button onClick={handleSubmitTask} color='primary'>
          {t('common:inputs.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddTaskDialog
