// TODO - this is a common file between projects. Refactor

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import NotFoundImage from './404.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 100
    },
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 360
  }
}))

const NotFound = () => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={classes.root}>
      <Grid
        container
        justify='center'
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant='h1'>
              {t('notFound.title')}
            </Typography>
            <Typography variant='subtitle2' dangerouslySetInnerHTML={{ __html: t('notFound.subtitle') }} />
            <img
              alt='Not Found'
              className={classes.image}
              src={NotFoundImage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default NotFound
