import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
// import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useLeadData from '../../../../hooks/useLeadData'
import { Button } from '@material-ui/core'
import Flex from '@leanlancer/ui/dist/components/atoms/Flex/Flex'
import SearchIcon from '../../icons/search.svg'
import ShoppingIcon from '../../icons/shopping-cart.svg'
import Display from '../../icons/monitor.svg'
import OtherIcon from '../../icons/other.svg'

const SEAPurpose = ({ pathwayStack, addToStack, goNext, ...props }) => {
  const { t } = useTranslation('client')
  const [leadSnap, leadData] = useLeadData()
  const [selected, setSelected] = useState(leadData?.seaPurpose || [])

  const handleSelectItem = (item) => (e) => {
    e.preventDefault()
    if (selected.includes(item)) {
      setSelected(selected.filter(i => i !== item))
    } else {
      setSelected([...selected, item])
    }
  }

  const handleSubmit = async () => {
    leadSnap.ref.update({
      type: 'sea',
      seaPurpose: selected
    })
    goNext('content')
  }

  const Actions = () => (
    <Flex dir='rtl'>
      <Button
        disabled={selected.length < 1}
        variant='outlined'
        color='primary'
        onClick={handleSubmit}
      >{t('common:inputs.next')}
      </Button>
    </Flex>
  )

  return (
    <>
      <FunnelQuestion
        title={t('leads.sea.purpose.title')}
        subtitle={t('leads.sea.purpose.subtitle')}
        questionHeader={t('leads.sea.purpose.questionHeader')}
        progress={50}
        actions={<Actions />}
      >
        <FunnelSelectCardGrid
          centered
          cards={[
            {
              title: t('leads.sea.purpose.answerSearch'),
              imgSrc: SearchIcon,
              onSelect: handleSelectItem('search'),
              selected: selected.includes('search')
            },
            {
              title: t('leads.sea.purpose.answerDisplay'),
              imgSrc: Display,
              onSelect: handleSelectItem('display'),
              selected: selected.includes('display')
            },
            {
              title: t('leads.sea.purpose.answerShopping'),
              imgSrc: ShoppingIcon,
              onSelect: handleSelectItem('shopping'),
              selected: selected.includes('shopping')
            },
            {
              title: t('common:other'),
              imgSrc: OtherIcon,
              onSelect: handleSelectItem('other'),
              selected: selected.includes('other')
            }
          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default SEAPurpose
