"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _lodash = require("lodash");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var saveKeys = ['displayName', 'email', 'emailVerified', 'isAnonymous', 'metadata.creationTime', 'metadata.lastSignInTime', 'phoneNumber', 'photoUrl', 'providerData'];

var saveUserData = function saveUserData(user) {
  var saveData = (0, _lodash.pick)(user.toJSON(), saveKeys);
  firebase.firestore().doc("users/".concat(user.uid)).set(saveData, {
    merge: true
  });
};

var useAuthUser = function useAuthUser(_ref) {
  var _ref$registrationData = _ref.registrationData,
      registrationData = _ref$registrationData === void 0 ? {} : _ref$registrationData;

  var _useState = (0, _react.useState)(true),
      _useState2 = _slicedToArray(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var _useState3 = (0, _react.useState)(firebase.auth().currentUser),
      _useState4 = _slicedToArray(_useState3, 2),
      authUser = _useState4[0],
      setAuthUser = _useState4[1];

  (0, _react.useEffect)(function () {
    return firebase.auth().onAuthStateChanged(function (user) {
      console.log('Auth state has changed:', user ? "".concat(user.uid) : 'none');
      setAuthUser(user);

      if (user) {
        console.log('saving user data');
        saveUserData(user);
      }

      if (user && !user.isAnonymous) {
        firebase.firestore().doc("users/".concat(user.uid)).set(registrationData, {
          merge: true
        });
      }

      setLoading(false);
    });
  }, []);
  return [authUser, loading];
};

var _default = useAuthUser;
exports["default"] = _default;