import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  makeStyles,
  Badge,
  Box
} from '@material-ui/core'
import { amber, green, red } from '@material-ui/core/colors'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  listItem: {
    justifyContent: 'space-between'
  },
  invoiceNumber: {
    marginRight: theme.spacing(2),
    fontSize: '1.5rem'
  },
  noInvoicesMessage: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  unpaidBadge: {
    color: theme.palette.getContrastText(amber[500]),
    backgroundColor: amber[500],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1)
  },
  paidBadge: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1)
  },
  overdueBadge: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1)
  }
}))

const ClientInvoicesListView = () => {
  const classes = useStyles()
  const history = useHistory()
  const [invoices, setInvoices] = useState([])
  const { t } = useTranslation('client')

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const snapshot = await firebase.firestore().collection('client_invoices').get()
        const invoicesData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        setInvoices(invoicesData)
      } catch (error) {
        console.error('Error fetching invoices:', error)
      }
    }

    fetchInvoices()
  }, [])

  const handleInvoiceClick = (invoiceId) => {
    history.push(`/invoices/${invoiceId}`) // Replace with the desired detail view route
  }

  const getBadgeClass = (status) => {
    switch (status) {
      case 'unpaid':
        return classes.unpaidBadge
      case 'paid':
        return classes.paidBadge
      case 'overdue':
        return classes.overdueBadge
      default:
        return ''
    }
  }

  if (invoices.length === 0) {
    return (
      <Typography variant='body1' className={classes.noInvoicesMessage}>
        {t('invoices.noInvoices')}
      </Typography>
    )
  }

  const calculateTotalAmount = (items) => {
    return items.reduce((total, item) => total + (item.quantity * item.unitPrice), 0)
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(amount)
  }

  return (
    <List>
      {invoices.map((invoice) => (
        <ListItem
          key={invoice.id}
          className={classes.listItem}
          button
          onClick={() => handleInvoiceClick(invoice.id)}
        >
          <ListItemText
            primary={
              <>
                <Typography variant='subtitle1' className={classes.invoiceNumber}>
                  {'INV-' + invoice.invoiceNumber.toString().padStart(3, '0')}
                </Typography>
              </>
            }
            secondary={invoice.clientName?.toUpperCase()}
          />
          <ListItemSecondaryAction>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              {invoice.items && invoice.items.length > 0
                ? (
                  <Typography variant='subtitle1'>
                    {formatCurrency(calculateTotalAmount(invoice.items))}
                  </Typography>
                  )
                : null}
              <Box>
                <Badge
                  classes={{ badge: getBadgeClass(invoice.status) }}
                  badgeContent={invoice.status.toUpperCase()}
                />
              </Box>
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}

export default ClientInvoicesListView
