import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Typography, makeStyles, Button, Toolbar } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Loader } from '@leanlancer/ui'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2)
  }
}))

const JobOfferDetailView = () => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const [jobOffer, setJobOffer] = useState({
    requirements: [],
    contact: {}
  })
  const { t } = useTranslation('client')

  useEffect(() => {
    const fetchJobOffer = async () => {
      try {
        const snapshot = await firebase.firestore().collection('job_offers').doc(id).get()
        const jobOfferData = snapshot.data()
        setJobOffer({ ...jobOfferData })
      } catch (error) {
        console.error('Error fetching job offer:', error)
      }
    }

    fetchJobOffer()
  }, [id])

  if (!jobOffer) {
    return <Loader />
  }

  const handleEditJobOffer = () => {
    // Handle the edit button click
    history.push(`/job-offers/edit/${id}`)
  }

  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Button onClick={handleEditJobOffer}>
          {t('client:jobOffers.editJobOffer')}
        </Button>
      </Toolbar>
      <Typography variant='h6'>{jobOffer.title}</Typography>
      <Typography variant='body1' className={classes.field}>
        {t('client:jobOffers.company')}: {jobOffer.company}
      </Typography>
      <Typography variant='body1' className={classes.field}>
        {t('client:jobOffers.description')}: {jobOffer.description}
      </Typography>
      <Typography variant='body1' className={classes.field}>
        {t('client:jobOffers.requirements')}:
        {jobOffer?.requirements?.map((requirement, index) => (
          <Typography key={index} variant='body2'>
            - {requirement}
          </Typography>
        ))}
      </Typography>
      <Typography variant='body1' className={classes.field}>
        {t('client:jobOffers.budget')}: {jobOffer.budget}
      </Typography>
      <Typography variant='body1' className={classes.field}>
        {t('client:jobOffers.duration')}: {jobOffer.duration}
      </Typography>
      <Typography variant='body1' className={classes.field}>
        {t('client:jobOffers.contact')}:
        <Typography variant='body2'>{t('client:jobOffers.name')}: {jobOffer?.contact?.name}</Typography>
        <Typography variant='body2'>{t('client:jobOffers.email')}: {jobOffer?.contact?.email}</Typography>
        <Typography variant='body2'>{t('client:jobOffers.phone')}: {jobOffer?.contact?.phone}</Typography>
      </Typography>

    </div>
  )
}

export default JobOfferDetailView
