import { ProjectDetail, TaskTable } from '@leanlancer/ui/dist/modules/Projects'
import {
  Box,
  Button,
  ButtonGroup,
  Tab,
  Tabs,
  Toolbar,
  makeStyles
} from '@material-ui/core'
import { TableChart, ViewModule } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'
import AddTaskDialog from './AddTaskDialog'
import EditTaskDialog from './EditTaskDialog'
import KanbanTaskView from './KanbanTaskView'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`
  },
  tabLink: {
    color: 'inherit'
  },
  spacer: {
    flex: 1
  }
}))

const ProjectDetailView = ({ id, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'client'])
  const match = useRouteMatch()
  const { pathname } = useLocation()

  const [project, setProject] = useState({})
  const [milestones, setMilestones] = useState([])
  const [tasks, setTasks] = useState([])
  const [taskTableData, setTaskTableData] = useState({})

  const [addTaskModalOpen, setAddTaskDialogOpen] = useState(false)
  const [editTaskModalOpen, setEditTaskModalOpen] = useState(false)
  const [editTaskData, setEditTaskData] = useState({})
  const [viewType, setViewType] = useState('table')

  useEffect(() => {
    if (!id) {
      throw new Error('No project id provided')
    }

    return firebase
      .firestore()
      .doc(`projects/${id}`)
      .onSnapshot((snap) => {
        if (snap.exists) {
          setProject(snap.data())
        } else {
          throw new Error(`Project with id ${id} not found`)
        }
      })
  }, [id])

  useEffect(() => {
    return firebase
      .firestore()
      .collection('project_milestones')
      .where('projectId', '==', id)
      .onSnapshot((snap) => {
        if (!snap.empty) {
          setMilestones(snap.docs.map((d) => ({ ...d.data(), id: d.id })))
        }
      })
  }, [id])

  useEffect(() => {
    return firebase
      .firestore()
      .collection('project_tasks')
      .where('projectId', '==', id)
      .onSnapshot((snap) => {
        if (!snap.empty) {
          setTasks(snap.docs.map((d) => ({ ...d.data(), id: d.id })))
        }
      })
  }, [id])

  useEffect(() => {
    const milestoneData = milestones?.map((milestone) => ({
      ...milestone,
      type: 'milestone'
    }))
    const tasksData = tasks?.map((task) => ({
      ...task,
      parentId: task.milestoneId,
      type: 'task'
    }))
    const taskTableData = [...milestoneData, ...tasksData]
    setTaskTableData(taskTableData)
  }, [project, tasks, milestones])

  const handleViewChange = (newViewType) => {
    setViewType(newViewType)
  }

  return (
    <>
      <AddTaskDialog
        open={addTaskModalOpen}
        onClose={() => setAddTaskDialogOpen(false)}
        projectId={id}
        projectMilestones={milestones}
      />
      <EditTaskDialog
        open={editTaskModalOpen}
        onClose={() => setEditTaskModalOpen(false)}
        task={editTaskData}
        projectId={id}
        projectMilestones={milestones}
      />
      <Tabs value={pathname}>
        <Tab
          label={t('projects.generalInformation')}
          component={Link}
          value={`${match.url}`}
          to={`${match.url}`}
          className={classes.tabLink}
        />
        <Tab
          label={t('projects.tasks')}
          component={Link}
          value={`${match.url}/tasks`}
          to={`${match.url}/tasks`}
          className={classes.tabLink}
        />
      </Tabs>
      <Switch>
        <Route path={`${match.path}`} exact>
          <div className={classes.container}>
            <ProjectDetail
              title={project.title}
              description={project.description}
              amount={`${project.totalCost}`}
              status={project.status}
              dueDate={project.endDate}
              {...props}
            />
          </div>
        </Route>
        <Route path={`${match.path}/tasks`} exact>
          <Toolbar>
            <Box display='flex' alignItems='center' width='100%'>
              <ButtonGroup disableElevation>
                <Button
                  value='table'
                  onClick={() => handleViewChange('table')}
                  variant={viewType === 'table' ? 'contained' : 'outlined'}
                >
                  <TableChart />
                </Button>
                <Button
                  value='kanban'
                  onClick={() => handleViewChange('kanban')}
                  variant={viewType === 'kanban' ? 'contained' : 'outlined'}
                >
                  <ViewModule />
                </Button>
              </ButtonGroup>
              <div className={classes.spacer} />
              <Button color='secondary' onClick={() => setAddTaskDialogOpen(true)}>
                {t('client:projects.addTask')}
              </Button>
            </Box>
          </Toolbar>
          <div className={classes.container}>
            {viewType === 'table'
              ? (
                <TaskTable
                  data={taskTableData}
                  editable
                  onEdit={(task) => {
                    setEditTaskData(task)
                    setEditTaskModalOpen(true)
                  }}
                />
                )
              : (
                <KanbanTaskView tasks={tasks} milestones={milestones} />
                )}
          </div>
        </Route>
      </Switch>
    </>
  )
}

export default ProjectDetailView
