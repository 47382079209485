import {
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import set from 'lodash.set'
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    margin: 'auto',
    padding: theme.spacing(2)
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  }
}))

const JobOfferEditView = () => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const { t } = useTranslation(['client', 'common'])

  const [jobOffer, setJobOffer] = useState({
    title: '',
    company: '',
    description: '',
    requirements: [],
    budget: '',
    duration: '',
    contact: {
      name: '',
      email: '',
      phone: ''
    },
    isActive: false
  })

  useEffect(() => {
    const fetchJobOffer = async () => {
      try {
        const snapshot = await firebase.firestore().collection('job_offers').doc(id).get()
        const jobOfferData = snapshot.data()
        setJobOffer(jobOfferData)
      } catch (error) {
        console.error('Error fetching job offer:', error)
      }
    }

    fetchJobOffer()
  }, [id])

  const handleInputChange = (event) => {
    const { name, value } = event.target

    const newJobOffer = { ...jobOffer }
    set(newJobOffer, name, value)

    setJobOffer(newJobOffer)
  }

  const handleSwitchChange = (event) => {
    const { checked } = event.target

    setJobOffer((prevJobOffer) => ({
      ...prevJobOffer,
      isActive: checked
    }))
  }

  const handleRemoveRequirement = (index) => {
    setJobOffer((prevJobOffer) => {
      const updatedRequirements = [...prevJobOffer.requirements]
      updatedRequirements.splice(index, 1) // Remove the requirement at the specified index
      return {
        ...prevJobOffer,
        requirements: updatedRequirements
      }
    })
  }

  const handleRequirementChange = (event, index) => {
    const { value } = event.target
    setJobOffer((prevJobOffer) => {
      const updatedRequirements = [...prevJobOffer.requirements]
      updatedRequirements[index] = value // Update the specific requirement with the new value
      return {
        ...prevJobOffer,
        requirements: updatedRequirements
      }
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // Remove empty requirements before submitting
    const filteredRequirements = jobOffer.requirements.filter((req) => req.trim() !== '')

    try {
      const db = firebase.firestore()
      await db.collection('job_offers').doc(id).update({
        ...jobOffer,
        requirements: filteredRequirements
      })
      console.log('Job offer updated:', id)

      // Navigate to job offers list page or show success message
      history.push('/job-offers')
    } catch (error) {
      console.error('Error updating job offer:', error)
      // Handle error case, e.g., show error message to the user
    }
  }

  return (
    <div style={{ marginTop: 64 }}>
      <Container maxWidth='lg'>
        <Typography variant='h4' align='center'>
          {t('client:jobOffers.editJobOffer')}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            className={classes.field}
            name='title'
            label={t('client:jobOffers.title')}
            value={jobOffer.title}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='company'
            label={t('client:jobOffers.company')}
            value={jobOffer.company}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='description'
            label={t('client:jobOffers.description')}
            multiline
            rows={4}
            value={jobOffer.description}
            onChange={handleInputChange}
            required
          />
          {jobOffer.requirements.map((requirement, index) => (
            <div key={index} className={classes.field}>
              <TextField
                name={`requirements-${index}`}
                label={`${t('client:jobOffers.requirement')} ${index + 1}`}
                value={requirement}
                onChange={(event) => handleRequirementChange(event, index)}
                required
              />
              <Button
                variant='outlined'
                onClick={() => handleRemoveRequirement(index)}
                style={{ marginLeft: '1rem' }}
              >
                {t('client:jobOffers.remove')}
              </Button>
            </div>
          ))}
          <Button
            className={classes.button}
            variant='outlined'
            onClick={() =>
              setJobOffer((prevJobOffer) => ({
                ...prevJobOffer,
                requirements: [...prevJobOffer.requirements, '']
              }))}
          >
            {t('client:jobOffers.addRequirement')}
          </Button>
          <TextField
            className={classes.field}
            name='budget'
            label={t('client:jobOffers.budget')}
            value={jobOffer.budget}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='duration'
            label={t('client:jobOffers.duration')}
            value={jobOffer.duration}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='contact.name'
            label={t('client:jobOffers.contactName')}
            value={jobOffer.contact.name}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='contact.email'
            label={t('client:jobOffers.contactEmail')}
            value={jobOffer.contact.email}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='contact.phone'
            label={t('client:jobOffers.contactPhone')}
            value={jobOffer.contact.phone}
            onChange={handleInputChange}
            required
          />
          <FormControlLabel
            className={classes.field}
            control={
              <Switch
                name='isActive'
                checked={jobOffer.isActive}
                onChange={handleSwitchChange}
                color='primary'
              />
            }
            label={t('client:jobOffers.active')}
          />
          <Button className={classes.button} variant='contained' color='primary' type='submit'>
            {t('common:inputs.save')}
          </Button>
        </form>
      </Container>
    </div>
  )
}

export default JobOfferEditView
