"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var resources = {
  en: {
    common: {
      yes: 'Yes',
      no: 'No',
      other: 'Other',
      errors: {
        unknown: 'An unknown error occurred',
        required: 'This field is required',
        emailInvalid: 'Invalid email address',
        urlInvalid: 'This does not appear to be a valid URL'
      },
      alerts: {
        saved: 'Saved!'
      },
      auth: {
        login: 'Login',
        logout: 'Logout',
        register: 'Register'
      },
      routes: {
        home: 'Home',
        profile: 'Profile',
        projects: 'Projects',
        settings: 'Settings',
        invoices: 'Invoices',
        jobOffers: 'Job Offers'
      },
      notFound: {
        title: '404: The page you are looking for isn’t here',
        subtitle: 'If you got here by mistake, you can navigate back to the home page <a href=\'/\'>here</a>'
      },
      logout: 'Logout',
      inputs: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        password: 'Password',
        phone: 'Phone number',
        city: 'City',
        country: 'Country',
        companyName: 'Company name',
        language: 'Language',
        cancel: 'Cancel',
        website: 'Website',
        companyHomepage: 'Company Homepage',
        "continue": 'Continue',
        next: 'Next',
        submit: 'Submit',
        saveAndClose: 'Save and Close',
        save: 'Save'
      },
      langs: {
        english: 'English',
        german: 'German',
        spanish: 'Spanish',
        french: 'French',
        mandarin: 'Mandarin Chinese',
        hindi: 'Hindi',
        portugese: 'Portuguese',
        russian: 'Russian',
        japanese: 'Japanese',
        turkish: 'Turkish',
        korean: 'Korean',
        vietnamese: 'Vietnamese',
        italian: 'Italian',
        arabic: 'Arabic',
        indonesian: 'Indonesian',
        polish: 'Polish',
        ukranian: 'Ukrainian',
        romanian: 'Romanian',
        dutch: 'Dutch',
        thai: 'Thai',
        malysian: 'Malaysian',
        greek: 'Greek',
        hungarian: 'Hungarian',
        czech: 'Czech'
      },
      footer: {
        caption: 'Empowering a new workforce'
      },
      projects: {
        keywords: 'Keywords',
        membersTitle: 'Project Members',
        noProjects: 'No projects are currently available',
        generalInformation: 'General Information',
        task: 'Task',
        tasks: 'Tasks',
        startDate: 'Start Date',
        endDate: 'End Date',
        dueDate: 'Due Date',
        status: 'Status',
        statuses: {
          pending: 'Pending',
          inProgress: 'In Progress',
          qa: 'QA',
          completed: 'Completed',
          cancelled: 'Cancelled'
        }
      },
      reviews: {
        maschinenbaupalm: {
          name: 'Maschinenbau Palm',
          content: 'We had our website created by LeanLancer and are very satisfied with the service. Price-wise, the offer was far below those of other agencies we asked. [...] We now rank on the first page of Google for our most important terms and were able to acquire some new customers. I can only recommend the service!'
        },
        talentselect: {
          name: 'Talent Select',
          content: 'LeanLancer supported me within a very short time and immediately convinced me with professional project management. The task was understood directly and sensible optimization recommendations followed even before the offer. Costs for implementation including commission were absolutely fair.'
        }
      },
      settings: {
        communicationTitle: 'Communication',
        communicationSubtitle: 'Here you can set your preferences for how we communicate with you',
        preferredCommunicationLanguage: 'Preferred Communication Language'
      },
      dashboard: {
        notificationsTitle: 'Notifications',
        noNotificationsMessage: 'You have no notifications',
        earningsTitle: 'Earnings',
        invoicesTitle: 'Invoices',
        tasksTitle: 'Tasks',
        projectsTitle: 'Projects',
        upcomingTasksTitle: 'Upcoming Tasks',
        noUpcomingTasksMessage: 'No upcoming tasks',
        currentProjectsTitle: 'Current Projects'
      }
    }
  },
  de: {
    common: {
      yes: 'Ja',
      no: 'Nein',
      other: 'Sonstiges',
      errors: {
        unknown: 'Ein unbekannter Fehler ist aufgetreten',
        required: 'Dieses Feld wird benötigt',
        emailInvalid: 'Ungültige E-Mail-Adresse',
        urlInvalid: 'Dies scheint keine gültige URL zu sein'
      },
      alerts: {
        saved: 'Gespeichert!'
      },
      auth: {
        login: 'Anmelden',
        logout: 'Abmelden',
        register: 'Registrieren'
      },
      routes: {
        home: 'Start',
        profile: 'Profil',
        projects: 'Projekte',
        settings: 'Einstellungen',
        invoices: 'Rechnungen',
        jobOffers: 'Job Angebote'
      },
      notFound: {
        title: '404: Die Seite, die du suchst, ist nicht hier',
        subtitle: 'Falls du versehentlich hierher gelangt bist, kannst du zurück <a href=\'/\'>zur Startseite</a> navigieren '
      },
      logout: 'Abmelden',
      inputs: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        password: 'Passwort',
        phone: 'Telefonnummer',
        companyName: 'Firmenname',
        city: 'Stadt',
        country: 'Land',
        language: 'Sprache',
        cancel: 'Abrechen',
        website: 'Webseite',
        companyHomepage: 'Firmen-Homepage',
        "continue": 'Weiter',
        next: 'Weiter',
        submit: 'Senden',
        saveAndClose: 'Speichern und schließen',
        save: 'Speichern'
      },
      langs: {
        english: 'Englisch',
        german: 'Deutsch',
        spanish: 'Spanisch',
        french: 'Französisch',
        mandarin: 'Mandarin-Chinesisch',
        hindi: 'Hindi',
        portugese: 'Portugiesisch',
        russian: 'Russisch',
        japanese: 'Japanisch',
        turkish: 'Türkisch',
        korean: 'Koreanisch',
        vietnamese: 'Vietnamesisch',
        italian: 'Italienisch',
        arabic: 'Arabisch',
        indonesian: 'Indonesisch',
        polish: 'Polnisch',
        ukranian: 'Ukrainisch',
        romanian: 'Rumänisch',
        dutch: 'Niederländisch',
        thai: 'Thailändisch',
        malysian: 'Malaysisch',
        greek: 'Griechisch',
        hungarian: 'Ungarisch',
        czech: 'Tschechisch'
      },
      footer: {
        caption: 'Empowering a new workforce'
      },
      projects: {
        keywords: 'Keywords',
        membersTitle: 'Projektbeteiligte',
        noProjects: 'Aktuell sind keine Projekte verfügbar',
        generalInformation: 'Allgemeine Informationen',
        task: 'Aufgabe',
        tasks: 'Aufgaben',
        startDate: 'Start Datum',
        endDate: 'Enddatum',
        dueDate: 'Fälligkeitsdatum',
        status: 'Status',
        statuses: {
          pending: 'Ausstehend',
          inProgress: 'In Bearbeitung',
          qa: 'In Überprüfung',
          completed: 'Abgeschlossen',
          cancelled: 'Abgebrochen'
        }
      },
      reviews: {
        maschinenbaupalm: {
          name: 'Maschinenbau Palm',
          content: 'Wir haben unsere Webseite von LeanLancer erstellen lassen und sind mit der Leistung sehr zufrieden. Preislich lag das Angebot weit unter denen anderer Agenturen, die wir angefragt haben. [...] Wir ranken nun für unsere wichtigsten Begriffe auf der ersten Seite bei Google und konnten einige Neukunden akquirieren. Ich kann den Service nur weiterempfehlen!'
        },
        talentselect: {
          name: 'Talent Select',
          content: 'LeanLancer hat mich innerhalb von kürzester Zeit betreut und mich sofort durch professionelles Projektmanagement überzeugt. Die Aufgabenstellung wurde direkt verstanden und sinnvolle Optimierungsempfehlungen folgten noch vor dem Angebot. Kosten für Umsetzung inklusive der Provision waren absolut fair.'
        }
      },
      settings: {
        communicationTitle: 'Kommunikation',
        communicationSubtitle: 'Hier kannst Du deine Präferenzen festlegen, wie wir mit dir kommunizieren',
        preferredCommunicationLanguage: 'Bevorzugte Kommunikationssprache'
      },
      dashboard: {
        notificationsTitle: 'Benachrichtigungen',
        noNotificationsMessage: 'Du hast keine Benachrichtigungen',
        earningsTitle: 'Umsatz',
        currentProjectsTitle: 'Aktuelle Projekte',
        upcomingTasksTitle: 'Anstehende Aufgaben',
        noUpcomingTasksMessage: 'Keine anstehenden Aufgaben',
        invoicesTitle: 'Rechnungen',
        tasksTitle: 'Aufgaben',
        projectsTitle: 'Projekte'
      }
    }
  }
};
var _default = resources;
exports["default"] = _default;