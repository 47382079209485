"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var usePageViews = function usePageViews(_ref) {
  var location = _ref.location;
  (0, _react.useEffect)(function () {
    firebase.analytics().logEvent('pageview', {
      location: location.pathname
    });
  }, [location]);
};

var _default = usePageViews;
exports["default"] = _default;