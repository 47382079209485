import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AuthUserContext } from '@leanlancer/common'

const RouteWithLayout = ({ layout: Layout, component: Component, reserved = false, ...rest }) => {
  const authUser = useContext(AuthUserContext)

  if (reserved) {
    if (!authUser || authUser.isAnonymous) {
      return <Redirect to='/sign-in' />
    }
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  )
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
}

export default RouteWithLayout
