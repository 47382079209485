import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import ClientInvoicesListView from './ClientInvoicesList'
import ClientInvoiceDetailView from './ClientInvoiceDetailView'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const ClientInvoices = ({ className, ...props }) => {
  const classes = useStyles()
  const params = useParams()

  return (
    <div className={clsx(classes.root, className)}>
      {params?.id ? <ClientInvoiceDetailView id={params.id} /> : <ClientInvoicesListView />}
    </div>
  )
}

export default ClientInvoices
