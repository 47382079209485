import { Flex } from '@leanlancer/ui'
import { FunnelQuestion } from '@leanlancer/ui/dist/modules/LeadFunnel'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { required } from '../../../common/validators'
import useLeadData from '../../../hooks/useLeadData'

const DescribeRequest = ({ goNext, ...props }) => {
  // const classes = useStyles()
  const { t } = useTranslation('client')

  const [leadSnap, leadData] = useLeadData()
  const { register, handleSubmit, errors } = useForm({
    defaultValues: leadData
  })

  const onSubmit = (values, event) => {
    leadSnap.ref.update({
      type: 'default',
      ...values
    })
    goNext('almost-there')
  }

  const Actions = (
    <Flex dir='rtl'>
      <Button
        variant='outlined'
        color='primary'
        type='submit'
      >{t('common:inputs.next')}
      </Button>
    </Flex>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <FunnelQuestion
        title={t('leads.describeRequest.title')}
        actions={Actions}
        progress={40}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h4' gutterBottom>{t('leads.describeRequest.jobTitle')}</Typography>
            <TextField
              variant='outlined'
              fullWidth
              name='title'
              inputRef={register(required)}
              error={!!errors?.title}
              helperText={errors?.title?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4' gutterBottom>{t('leads.describeRequest.jobDescription')}</Typography>
            <TextField
              variant='outlined'
              fullWidth
              multiline
              rows={4}
              name='description'
              inputRef={register(required)}
              error={!!errors?.description}
              helperText={errors?.description?.message}
            />
          </Grid>
        </Grid>
      </FunnelQuestion>
    </form>
  )
}

export default DescribeRequest
