import { AuthUserContext, useSnackbar } from '@leanlancer/common'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GeneralInfo from './GeneralInfo'
import ProfileBio from './ProflieBio'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  saveButton: {
    float: 'right'
  }
}))

const INITIAL_VALUES = {
  generalInfo: {
    firstName: '',
    lastName: '',
    phone: '',
    city: '',
    country: '',
    website: ''
  },
  biography: ''
}

const Profile = () => {
  const classes = useStyles()
  const authUser = useContext(AuthUserContext)
  const [profileVals, setProfileVals] = useState(INITIAL_VALUES)
  const snackbar = useSnackbar()
  const { t } = useTranslation('client')

  useEffect(() => {
    const docRef = firebase.firestore().doc(`client_profiles/${authUser.uid}`)
    return docRef.onSnapshot(doc => {
      if (doc.exists) {
        setProfileVals(doc.data() || INITIAL_VALUES)
      } else {
        setProfileVals(INITIAL_VALUES)
      }
    })
  }, [authUser])

  const handleSave = async (e) => {
    const docRef = firebase.firestore().doc(`client_profiles/${authUser.uid}`)
    await docRef.set(profileVals, { merge: true })
    snackbar({
      type: 'open',
      message: t('profile.savedAlert')
    })
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <Button color='primary' variant='outlined' className={classes.saveButton} onClick={handleSave}>{t('profile.save')}</Button>
        </Grid>

        <Grid
          item
          xs={12}
        >
          <GeneralInfo
            values={profileVals.generalInfo}
            onChange={(vals) => setProfileVals({ ...profileVals, generalInfo: vals })}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <ProfileBio
            values={profileVals.biography}
            onChange={(vals) => setProfileVals({ ...profileVals, biography: vals })}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <Button color='primary' variant='outlined' className={classes.saveButton} onClick={handleSave}>{t('profile.save')}</Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default Profile
