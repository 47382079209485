import {
  Button,
  Container,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core'
import set from 'lodash.set'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    margin: 'auto',
    padding: theme.spacing(2)
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  }
}))

const JobOfferCreateView = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation('client')

  const [jobOffer, setJobOffer] = useState({
    title: '',
    company: '',
    description: '',
    requirements: [],
    budget: '',
    duration: '',
    contact: {
      name: '',
      email: '',
      phone: ''
    }
  })

  const handleInputChange = (event) => {
    const { name, value } = event.target

    const newJobOffer = { ...jobOffer }
    set(newJobOffer, name, value)

    setJobOffer((prevJobOffer) => set({ ...prevJobOffer }, name, value))
  }

  const handleRequirementChange = (event, index) => {
    const { value } = event.target
    setJobOffer((prevJobOffer) => {
      const updatedRequirements = [...prevJobOffer.requirements]
      updatedRequirements[index] = value
      return {
        ...prevJobOffer,
        requirements: updatedRequirements
      }
    })
  }

  const handleSwitchChange = (event) => {
    const { checked } = event.target

    setJobOffer((prevJobOffer) => ({
      ...prevJobOffer,
      isActive: checked
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const db = firebase.firestore()
      const docRef = await db.collection('job_offers').add(jobOffer)
      console.log('New job offer created with ID:', docRef.id)

      // Reset the form after successful submission
      setJobOffer({
        title: '',
        company: '',
        description: '',
        requirements: [],
        budget: '',
        duration: '',
        contact: {
          name: '',
          email: '',
          phone: ''
        }
      })

      // Navigate to job offers list page or show success message
      history.push('/job-offers')
    } catch (error) {
      console.error('Error creating job offer:', error)
      // Handle error case, e.g., show error message to the user
    }
  }

  return (
    <div style={{ marginTop: 64 }}>
      <Container maxWidth='lg'>
        <Typography variant='h4' align='center'>
          {t('client:jobOffers.createJobOffer')}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            className={classes.field}
            name='title'
            label={t('client:jobOffers.title')}
            value={jobOffer.title}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='company'
            label={t('client:jobOffers.company')}
            value={jobOffer.company}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='description'
            label={t('client:jobOffers.description')}
            multiline
            rows={4}
            value={jobOffer.description}
            onChange={handleInputChange}
            required
          />
          {jobOffer.requirements.map((requirement, index) => (
            <TextField
              key={index}
              className={classes.field}
              name={`requirements-${index}`}
              label={`${t('client:jobOffers.requirement')} ${index + 1}`}
              value={requirement}
              onChange={(event) => handleRequirementChange(event, index)}
              required
            />
          ))}
          <FormControlLabel
            className={classes.field}
            control={
              <Switch
                name='isActive'
                checked={jobOffer.isActive}
                onChange={handleSwitchChange}
                color='primary'
              />
            }
            label={t('client:jobOffers.active')}
          />
          <Button
            className={classes.button}
            variant='outlined'
            onClick={() => setJobOffer((prevJobOffer) => ({ ...prevJobOffer, requirements: [...prevJobOffer.requirements, ''] }))}
          >
            {t('client:jobOffers.addRequirement')}
          </Button>
          <TextField
            className={classes.field}
            name='budget'
            label={t('client:jobOffers.budget')}
            value={jobOffer.budget}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='duration'
            label={t('client:jobOffers.duration')}
            value={jobOffer.duration}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='contact.name'
            label={t('client:jobOffers.contactName')}
            value={jobOffer.contact.name}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='contact.email'
            label={t('client:jobOffers.contactEmail')}
            value={jobOffer.contact.email}
            onChange={handleInputChange}
            required
          />
          <TextField
            className={classes.field}
            name='contact.phone'
            label={t('client:jobOffers.contactPhone')}
            value={jobOffer.contact.phone}
            onChange={handleInputChange}
            required
          />
          <Button className={classes.button} variant='contained' color='primary' type='submit'>
            {t('client:jobOffers.createJobOffer')}
          </Button>
        </form>
      </Container>
    </div>
  )
}

export default JobOfferCreateView
