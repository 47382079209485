import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
// import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import ServiceImg from '../icons/services.svg'
import GoalsImg from '../icons/goals.svg'
import { useTranslation } from 'react-i18next'

const Start = ({ pathwayStack, addToStack, goNext, ...props }) => {
  const { t } = useTranslation('client')

  const handleServicesSelect = async () => {
    goNext('services')
  }

  const handleGoalsSelect = async () => {
    goNext('goals')
  }

  return (
    <>
      <FunnelQuestion
        title={t('leads.start.title')}
        progress={0}
      >
        <FunnelSelectCardGrid
          centered
          cards={[
            {
              title: t('leads.start.selectServices'),
              imgSrc: ServiceImg,
              onSelect: handleServicesSelect
            },
            {
              title: t('leads.start.selectGoals'),
              imgSrc: GoalsImg,
              onSelect: handleGoalsSelect
            }
          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default Start
