import { useSnackbar } from '@leanlancer/common'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  makeStyles
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2) // Add vertical spacing between fields
  }
}))

const EditTaskDialog = ({ open, onClose, task, projectMilestones }) => {
  const classes = useStyles()

  const [title, setTitle] = useState(task?.title || '')
  const [status, setStatus] = useState(task?.status || '')
  const [startDate, setStartDate] = useState(task?.startDate || '')
  const [endDate, setEndDate] = useState(task?.endDate || '')
  const [milestoneId, setMilestoneId] = useState(task?.milestoneId || '')
  const { t } = useTranslation(['common', 'client'])

  const snackbar = useSnackbar()

  useEffect(() => {
    if (task) {
      setTitle(task.title)
      setStatus(task.status)
      setStartDate(task.startDate)
      setEndDate(task.endDate)
      setMilestoneId(task.milestoneId)
    }
  }, [task])

  const handleTitleChange = (event) => {
    setTitle(event.target.value)
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
  }

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value)
  }

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value)
  }

  const handleMilestoneChange = (event) => {
    const selectedMilestoneId = event.target.value
    setMilestoneId(selectedMilestoneId === '' ? '' : selectedMilestoneId)
  }

  const handleSubmitTask = () => {
    const updatedTaskData = {
      ...task,
      title,
      status,
      startDate,
      endDate,
      milestoneId
    }

    const firestore = firebase.firestore()
    firestore
      .collection('project_tasks')
      .doc(task.id)
      .set(updatedTaskData, { merge: true })
      .then(() => {
        console.log(`Task updated successfully! ID: ${task.id}`)
        snackbar({
          type: 'open',
          severity: 'success',
          message: t('client:projects.taskUpdated')
        })
      })
      .catch((error) => {
        console.error('Error updating task:', error)
        snackbar({
          type: 'open',
          severity: 'error',
          message: t('client:projects.taskUpdateError')
        })
      })

    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {t('client:projects.editTask')}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth className={classes.formControl}>
          <TextField label='Title' value={title} onChange={handleTitleChange} />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <Select value={status} onChange={handleStatusChange}>
            <MenuItem value='pending'>{t('common:projects.statuses.pending')}</MenuItem>
            <MenuItem value='in_progress'>{t('common:projects.statuses.inProgress')}</MenuItem>
            <MenuItem value='qa'>{t('common:projects.statuses.qa')}</MenuItem>
            <MenuItem value='completed'>{t('common:projects.statuses.completed')}</MenuItem>
            <MenuItem value='cancelled'>{t('common:projects.statuses.cancelled')}</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            label={t('common:projects.startDate')}
            type='date'
            value={startDate}
            onChange={handleStartDateChange}
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            label={t('common:projects.endDate')}
            type='date'
            value={endDate}
            onChange={handleEndDateChange}
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <Select value={milestoneId} onChange={handleMilestoneChange}>
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {projectMilestones.map((milestone) => (
              <MenuItem key={milestone.id} value={milestone.id}>
                {milestone.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {t('common:inputs.cancel')}
        </Button>
        <Button onClick={handleSubmitTask} color='primary' variant='contained'>
          {t('common:inputs.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditTaskDialog
