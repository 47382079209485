"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Dashboard", {
  enumerable: true,
  get: function get() {
    return _Dashboard.default;
  }
});
Object.defineProperty(exports, "Minimal", {
  enumerable: true,
  get: function get() {
    return _Minimal.default;
  }
});

var _Dashboard = _interopRequireDefault(require("./Dashboard"));

var _Minimal = _interopRequireDefault(require("./Minimal"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }