import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
// import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import CheckIcon from '@leanlancer/ui/src/svgs/check.svg'
import CloseIcon from '@leanlancer/ui/src/svgs/close.svg'
import { useTranslation } from 'react-i18next'
import useLeadData from '../../../../hooks/useLeadData'

const AppStart = ({ pathwayStack, addToStack, goNext, ...props }) => {
  const { t } = useTranslation('client')
  const [leadSnap] = useLeadData()
  leadSnap.ref.update({
    type: 'app'
  })

  const handleYesSelect = async () => {
    leadSnap.ref.update({
      type: 'app',
      existingApplication: true
    })
    goNext('platforms')
  }

  const handleNoSelect = async () => {
    leadSnap.ref.update({
      type: 'app',
      existingApplication: false
    })
    goNext('platforms')
  }

  return (
    <>
      <FunnelQuestion
        title={t('leads.app.start.title')}
        subtitle={t('leads.app.start.subtitle')}
        questionHeader={t('leads.app.start.questionHeader')}
        progress={0}
      >
        <FunnelSelectCardGrid
          centered
          cards={[
            {
              title: t('common:yes'),
              imgSrc: CheckIcon,
              onSelect: handleYesSelect
            },
            {
              title: t('common:no'),
              imgSrc: CloseIcon,
              onSelect: handleNoSelect
            }
          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default AppStart
