import { Skeleton } from '@material-ui/lab'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ProjectList } from '@leanlancer/ui/dist/modules/Projects'
import { AuthUserContext } from '@leanlancer/common'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  skelteonRow: {
    marginBottom: theme.spacing(4)
  }
}))

export default ({ ...props }) => {
  const classes = useStyles()
  const history = useHistory()
  const authUser = useContext(AuthUserContext)
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    return firebase.firestore().collection('projects').where('permissions.viewers', 'array-contains', authUser.uid)
      .onSnapshot(snap => {
        setProjects(snap.docs.map(s => ({
          id: s.id,
          ...s.data(),
          amount: s.data().totalCost,
          dueDate: s.data().endDate
        })))
        setLoading(false)
      })
  }, [authUser?.uid])

  if (loading) {
    return (
      <>
        <Skeleton className={classes.skelteonRow} variant='rect' width='100%' height={80} />
        <Skeleton className={classes.skelteonRow} variant='rect' width='100%' height={80} />
        <Skeleton className={classes.skelteonRow} variant='rect' width='100%' height={80} />
      </>
    )
  }

  return (
    <ProjectList
      projects={projects}
      onProjectSelect={(projectId) => {
        history.push(`/projects/${projectId}`)
      }}
    />
  )
}
