import { useDocument } from '@leanlancer/common'
import { AuthUserContext } from '@leanlancer/common/dist/contexts'
import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import qs from 'qs'

const db = firebase.default.firestore()

export const LeadDataContext = createContext()
export const LeadDataProvider = ({ children }) => {
  const authUser = useContext(AuthUserContext)
  const [loadingLeads, setLoadingLeads] = useState(true)
  const [ref, setRef] = useState(null)
  const location = useLocation()
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })

  const checkForExistingLead = async () => {
    const snap = await db.collection('leads')
      .where('userId', '==', authUser.uid)
      .where('submitted', '==', false)
      .get()
    if (snap.size) {
      setRef(snap.docs[0].ref)
      snap.docs[0].ref.update({
        params
      })
    } else {
      const newLead = await db.collection('leads').add({
        submitted: false,
        userId: authUser.uid,
        type: 'default',
        params
      })
      setRef(newLead)
    }
    setLoadingLeads(false)
  }

  useEffect(() => {
    checkForExistingLead()
    // eslint-disable-next-line
  }, [authUser?.uid])

  const lead = useDocument(ref)
  const [, , docLoading] = lead

  if (loadingLeads || docLoading) {
    return null // TODO: loader?
  }

  return (
    <LeadDataContext.Provider value={lead}>
      {children}
    </LeadDataContext.Provider>
  )
}

const useLeadData = () => useContext(LeadDataContext)

export default useLeadData
