import { AuthUserContext } from '@leanlancer/common'
import { FullPageLoader, BackButton } from '@leanlancer/ui'
import { Hidden } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useContext, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import { LeadDataProvider } from '../../hooks/useLeadData'
import leadRoutes from './leadRoutes'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4)
    }
  },
  backButton: {
    margin: theme.spacing(1),
    color: theme.palette.common.white
    // backgroundColor: theme.palette.background.paper,
    // '&:hover, &.Mui-focusVisible': { backgroundColor: theme.palette.background.paper }
  }
}))

const Leads = ({ ...props }) => {
  const classes = useStyles()
  const match = useRouteMatch()
  const history = useHistory()
  const [pathwayStack, setPathwayStack] = useState([])
  const authUser = useContext(AuthUserContext)

  if (!authUser) {
    // login anon
    firebase.auth().signInAnonymously()
  }

  const goNext = (...additionalPaths) => {
    const newStack = [...pathwayStack, ...additionalPaths]
    const next = newStack.shift()
    setPathwayStack(newStack)
    history.push(next)
  }

  if (!authUser) {
    return <FullPageLoader />
  }

  return (
    <div className={classes.root}>
      <LeadDataProvider>
        <Hidden smDown>
          <BackButton onClick={() => history.goBack()} className={classes.backButton} />
        </Hidden>
        <Switch>
          {Object.keys(leadRoutes).map(path => {
            const Component = leadRoutes[path]
            return (
              <Route
                key={path}
                path={`${match.path}/${path}`}
                render={() => (
                  <Component pathwayStack={pathwayStack} goNext={goNext} />
                )}
              />
            )
          })}
          <Route path={match.path} exact>
            <Redirect to={`${match.path}/start`} />
          </Route>
          <Route path='*'>
            <Redirect to='/not-found' />
          </Route>
        </Switch>
      </LeadDataProvider>
    </div>
  )
}

Leads.propTypes = {

}

export default Leads
