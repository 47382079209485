"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "PROFILE_LANGUAGES", {
  enumerable: true,
  get: function get() {
    return _profileLanguages["default"];
  }
});

var _profileLanguages = _interopRequireDefault(require("./profileLanguages"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }