import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
// import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useLeadData from '../../../../hooks/useLeadData'
import { Button } from '@material-ui/core'
import Flex from '@leanlancer/ui/dist/components/atoms/Flex/Flex'
import ImplementationIcon from '../../icons/planning.svg'
import AddIcon from '../../icons/add-file.svg'
import ChangeIcon from '../../icons/shuffle.svg'
import OtherIcon from '../../icons/other.svg'

const WebshopChanges = ({ pathwayStack, addToStack, goNext, ...props }) => {
  const { t } = useTranslation('client')
  const [leadSnap, leadData] = useLeadData()
  const [selected, setSelected] = useState(leadData?.webshopChanges || [])

  const handleSelectItem = (item) => (e) => {
    e.preventDefault()
    if (selected.includes(item)) {
      setSelected(selected.filter(i => i !== item))
    } else {
      setSelected([...selected, item])
    }
  }

  const handleSubmit = async () => {
    leadSnap.ref.update({
      type: 'webshop',
      webshopChanges: selected
    })
    goNext('purpose')
  }

  const Actions = () => (
    <Flex dir='rtl'>
      <Button
        disabled={selected.length < 1}
        variant='outlined'
        color='primary'
        onClick={handleSubmit}
      >{t('common:inputs.next')}
      </Button>
    </Flex>
  )

  return (
    <>
      <FunnelQuestion
        title={t('leads.webshop.changes.title')}
        subtitle={t('leads.webshop.changes.subtitle')}
        questionHeader={t('leads.webshop.changes.questionHeader')}
        progress={25}
        actions={<Actions />}
      >
        <FunnelSelectCardGrid
          centered
          cards={[
            {
              title: t('leads.webshop.changes.answerProducts'),
              imgSrc: AddIcon,
              onSelect: handleSelectItem('products'),
              selected: selected.includes('products')
            },
            {
              title: t('leads.webshop.changes.answerFunctions'),
              imgSrc: ImplementationIcon,
              onSelect: handleSelectItem('functionality'),
              selected: selected.includes('functionality')
            },
            {
              title: t('leads.webshop.changes.answerChangePlatform'),
              imgSrc: ChangeIcon,
              onSelect: handleSelectItem('platform-change'),
              selected: selected.includes('platform-change')
            },
            {
              title: t('common:other'),
              imgSrc: OtherIcon,
              onSelect: handleSelectItem('other'),
              selected: selected.includes('other')
            }
          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default WebshopChanges
