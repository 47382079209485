"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var white = '#FFFFFF';
var black = '#000000';
var _default = {
  black: black,
  white: white,
  primary: {
    contrastText: white,
    dark: '#031e32',
    main: '#2f455c',
    light: '#5b7089'
  },
  secondary: {
    contrastText: white,
    dark: '#009ccb',
    main: '#1DCDFE',
    light: '#71ffff'
  },
  tertiary: {
    contrastText: white,
    dark: '#009e83',
    main: '#21D0B2',
    light: '#6affe4'
  },
  success: {
    contrastText: white,
    dark: _core.colors.green[900],
    main: _core.colors.green[600],
    light: _core.colors.green[400]
  },
  info: {
    contrastText: white,
    dark: _core.colors.blue[900],
    main: _core.colors.blue[600],
    light: _core.colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: _core.colors.orange[900],
    main: _core.colors.orange[600],
    light: _core.colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: _core.colors.red[900],
    main: _core.colors.red[600],
    light: _core.colors.red[400]
  },
  text: {
    primary: _core.colors.blueGrey[900],
    secondary: _core.colors.blueGrey[600],
    link: _core.colors.blue[600]
  },
  background: {
    default: '#F2F2F2',
    dark: '#c1c3c5',
    paper: '#FFFFFF'
  },
  icon: _core.colors.blueGrey[600],
  divider: _core.colors.grey[200]
};
exports.default = _default;