"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _core = require("@material-ui/core");

var _palette = _interopRequireDefault(require("./palette"));

var _typography = _interopRequireDefault(require("./typography"));

var _overrides = _interopRequireDefault(require("./overrides"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var theme = (0, _core.createMuiTheme)({
  palette: _palette.default,
  typography: _typography.default,
  overrides: _overrides.default,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});
var _default = theme;
exports.default = _default;