import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
// import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import CheckIcon from '@leanlancer/ui/src/svgs/check.svg'
import CloseIcon from '@leanlancer/ui/src/svgs/close.svg'
import { useTranslation } from 'react-i18next'
import useLeadData from '../../../../hooks/useLeadData'

const SEAContent = ({ pathwayStack, addToStack, goNext, ...props }) => {
  const { t } = useTranslation('client')
  const [leadSnap] = useLeadData()

  const handleYesSelect = async () => {
    leadSnap.ref.update({
      type: 'sea',
      existingContent: true
    })
    goNext('../verification')
  }

  const handleNoSelect = async () => {
    leadSnap.ref.update({
      type: 'sea',
      existingContent: false
    })
    goNext('../verification')
  }

  return (
    <>
      <FunnelQuestion
        title={t('leads.sea.content.title')}
        subtitle={t('leads.sea.content.subtitle')}
        questionHeader={t('leads.sea.content.questionHeader')}
        progress={75}
      >
        <FunnelSelectCardGrid
          centered
          cards={[
            {
              title: t('common:yes'),
              imgSrc: CheckIcon,
              onSelect: handleYesSelect
            },
            {
              title: t('common:no'),
              imgSrc: CloseIcon,
              onSelect: handleNoSelect
            }
          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default SEAContent
