import DescribeRequest from './pages/DescribeRequest'
import Goals from './pages/Goals'
import Start from './pages/Start'
import AlmostThere from './pages/AlmostThere'
import Contact from './pages/Contact'
import ThankYou from './pages/ThankYou'
import Services from './pages/Services'
import Verification from './pages/Verification'
import WebsiteStart from './pages/website/WebsiteStart'
import WebsiteChanges from './pages/website/WebsiteChanges'
import WebsitePurpose from './pages/website/WebsitePurpose'
import WebsiteContent from './pages/website/WebsiteContent'
import SeoStart from './pages/seo/SEOStart'
import SeoStatus from './pages/seo/SEOStatus'
import SeoChanges from './pages/seo/SEOChanges'
import SeoPurpose from './pages/seo/SEOPurpose'
import WebshopStart from './pages/webshop/WebshopStart'
import WebshopChanges from './pages/webshop/WebshopChanges'
import WebshopPurpose from './pages/webshop/WebshopPurpose'
import WebshopContent from './pages/webshop/WebshopContent'
import AppStart from './pages/app/AppStart'
import AppPlatforms from './pages/app/AppPlatforms'
import AppPurpose from './pages/app/AppPurpose'
import AppDesign from './pages/app/AppDesign'
import SEAStart from './pages/sea/SEAStart'
import SEAChanges from './pages/sea/SEAChanges'
import SEAPurpose from './pages/sea/SEAPurpose'
import SEAContent from './pages/sea/SEAContent'

export default {
  start: Start,
  goals: Goals,
  services: Services,
  'describe-request': DescribeRequest,
  'almost-there': AlmostThere,
  verification: Verification,
  contact: Contact,
  'thank-you': ThankYou,
  // website creation funnel
  'website/start': WebsiteStart,
  'website/purpose': WebsitePurpose,
  'website/changes': WebsiteChanges,
  'website/content': WebsiteContent,
  // seo funnel
  'seo/start': SeoStart,
  'seo/status': SeoStatus,
  'seo/changes': SeoChanges,
  'seo/purpose': SeoPurpose,
  // webshop funnel,
  'webshop/start': WebshopStart,
  'webshop/changes': WebshopChanges,
  'webshop/purpose': WebshopPurpose,
  'webshop/content': WebshopContent,
  // app funnel
  'app/start': AppStart,
  'app/platforms': AppPlatforms,
  'app/purpose': AppPurpose,
  'app/design': AppDesign,
  // sea funnel
  'sea/start': SEAStart,
  'sea/changes': SEAChanges,
  'sea/purpose': SEAPurpose,
  'sea/content': SEAContent
}
