import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField
} from '@material-ui/core'
import ProfilePicture from './ProfilePicture'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  root: {},
  cardActions: {
    display: 'flex',
    flexDirection: 'row-reverse'
  }
}))

const GeneralInfo = ({ className, values = {}, onChange, ...rest }) => {
  const classes = useStyles()
  const { t } = useTranslation(['common', 'client'])

  const handleChange = event => {
    const newVals = {
      ...values,
      [event.target.name]: event.target.value
    }
    onChange(newVals)
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete='off'
        noValidate
      >
        <CardHeader
          title={t('client:profile.general.title')}
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
            >
              <ProfilePicture
                user={values}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              md={9}
            >
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label={t('inputs.firstName')}
                  margin='dense'
                  name='firstName'
                  onChange={handleChange}
                  value={values.firstName}
                  variant='outlined'
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label={t('inputs.lastName')}
                  margin='dense'
                  name='lastName'
                  onChange={handleChange}
                  value={values.lastName}
                  variant='outlined'
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label={t('inputs.phone')}
                  margin='dense'
                  name='phone'
                  onChange={handleChange}
                  type='number'
                  value={values.phone}
                  variant='outlined'
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label={t('inputs.companyName')}
                  margin='dense'
                  name='companyName'
                  onChange={handleChange}
                  value={values.companyName}
                  variant='outlined'
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label={t('inputs.city')}
                  margin='dense'
                  name='city'
                  onChange={handleChange}
                  value={values.city}
                  variant='outlined'
                />

              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label={t('inputs.country')}
                  margin='dense'
                  name='country'
                  onChange={handleChange}
                  value={values.country}
                  variant='outlined'
                />
              </Grid>

              <Grid
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  label={t('inputs.website')}
                  margin='dense'
                  name='website'
                  onChange={handleChange}
                  value={values.website}
                  variant='outlined'
                />
              </Grid>
            </Grid>

          </Grid>
        </CardContent>
      </form>
    </Card>
  )
}

GeneralInfo.propTypes = {
  className: PropTypes.string,
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default GeneralInfo
