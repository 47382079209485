"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _core = require("@material-ui/core");

// TODO refactor
var useBreakpoint = function useBreakpoint() {
  var theme = (0, _core.useTheme)();
  var xs = (0, _core.useMediaQuery)(theme.breakpoints.only('xs'));
  var sm = (0, _core.useMediaQuery)(theme.breakpoints.only('sm'));
  var md = (0, _core.useMediaQuery)(theme.breakpoints.only('md'));
  var lg = (0, _core.useMediaQuery)(theme.breakpoints.only('lg'));
  var xl = (0, _core.useMediaQuery)(theme.breakpoints.only('xl'));
  return xs && 'xs' || sm && 'sm' || md && 'md' || lg && 'lg' || xl && 'xl';
};

var _default = useBreakpoint;
exports["default"] = _default;