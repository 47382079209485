import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
// import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useLeadData from '../../../../hooks/useLeadData'
import { Button } from '@material-ui/core'
import Flex from '@leanlancer/ui/dist/components/atoms/Flex/Flex'
import TimeSavingIcon from '../../icons/clock.svg'
import UsabilityIcon from '../../icons/laptop-with-check.svg'
import SalesIcon from '../../icons/sales.svg'
import OtherIcon from '../../icons/other.svg'

const WebshopPurpose = ({ pathwayStack, addToStack, goNext, ...props }) => {
  const { t } = useTranslation('client')
  const [leadSnap, leadData] = useLeadData()
  const [selected, setSelected] = useState(leadData?.webshopPurpose || [])

  const handleSelectItem = (item) => (e) => {
    e.preventDefault()
    if (selected.includes(item)) {
      setSelected(selected.filter(i => i !== item))
    } else {
      setSelected([...selected, item])
    }
  }

  const handleSubmit = async () => {
    leadSnap.ref.update({
      type: 'webshop',
      webshopPurpose: selected
    })
    goNext('content')
  }

  const Actions = () => (
    <Flex dir='rtl'>
      <Button
        disabled={selected.length < 1}
        variant='outlined'
        color='primary'
        onClick={handleSubmit}
      >{t('common:inputs.next')}
      </Button>
    </Flex>
  )

  return (
    <>
      <FunnelQuestion
        title={t('leads.webshop.purpose.title')}
        subtitle={t('leads.webshop.purpose.subtitle')}
        questionHeader={t('leads.webshop.purpose.questionHeader')}
        progress={50}
        actions={<Actions />}
      >
        <FunnelSelectCardGrid
          centered
          cards={[
            {
              title: t('leads.webshop.purpose.answerSales'),
              imgSrc: SalesIcon,
              onSelect: handleSelectItem('sales'),
              selected: selected.includes('sales')
            },
            {
              title: t('leads.webshop.purpose.answerSaveTime'),
              imgSrc: TimeSavingIcon,
              onSelect: handleSelectItem('save-time'),
              selected: selected.includes('save-time')
            },
            {
              title: t('leads.webshop.purpose.answerUsability'),
              imgSrc: UsabilityIcon,
              onSelect: handleSelectItem('usability'),
              selected: selected.includes('usability')
            },
            {
              title: t('common:other'),
              imgSrc: OtherIcon,
              onSelect: handleSelectItem('other'),
              selected: selected.includes('other')
            }
          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default WebshopPurpose
