"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var isEqual = function isEqual(v1, v2) {
  var bothNull = !v1 && !v2;
  var equal = !!v1 && !!v2 && v1.isEqual(v2);
  return bothNull || equal;
};

var useDocument = function useDocument() {
  var ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
  var docRef = (0, _react.useRef)(ref);

  var _useState = (0, _react.useState)(true),
      _useState2 = _slicedToArray(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var _useState3 = (0, _react.useState)(null),
      _useState4 = _slicedToArray(_useState3, 2),
      documentData = _useState4[0],
      setDocumentData = _useState4[1];

  var _useState5 = (0, _react.useState)(null),
      _useState6 = _slicedToArray(_useState5, 2),
      docSnapshot = _useState6[0],
      setDocSnapshot = _useState6[1];

  var _useState7 = (0, _react.useState)(null),
      _useState8 = _slicedToArray(_useState7, 2),
      error = _useState8[0],
      setError = _useState8[1];

  if (!isEqual(ref, docRef.current)) {
    docRef.current = ref;
  }

  (0, _react.useEffect)(function () {
    if (!docRef.current) {
      return console.warn('no ref provided to useDocument');
    }

    setLoading(true);
    return docRef.current.onSnapshot(function (docSnapshot) {
      if (docSnapshot.exists) {
        setDocumentData(docSnapshot.data());
      } else {
        setDocumentData(null);
      }

      setDocSnapshot(docSnapshot);
      setLoading(false);
    }, function (err) {
      console.error(err);
      setError(err);
      setLoading(false);
    });
  }, [docRef.current]);

  if (!ref) {
    return [null, null, false, null];
  }

  return [docSnapshot, documentData, loading, error];
};

var _default = useDocument;
exports["default"] = _default;