import { Flex, TrustBox } from '@leanlancer/ui'
import { FunnelQuestion } from '@leanlancer/ui/dist/modules/LeadFunnel'
import { Button, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isURL from 'validator/lib/isURL'
import { email, required } from '../../../common/validators'
import useLeadData from '../../../hooks/useLeadData'

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const Final = ({ goNext, ...props }) => {
  const classes = useStyles()
  const { t } = useTranslation('client')
  const [leadSnap, leadData] = useLeadData()
  const { register, handleSubmit, errors } = useForm({
    defaultValues: leadData
  })

  const onSubmit = (values) => {
    leadSnap.ref.update({ ...values, submitted: true })
    firebase.analytics().logEvent('lead_submitted', {
      lead: { ...values, submitted: true }
    })
    goNext('thank-you')
  }

  const Actions = () => (
    <Flex dir='rtl'>
      <Button
        variant='contained'
        color='primary'
        type='submit'
      >{t('common:inputs.submit')}
      </Button>
    </Flex>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FunnelQuestion
        title={t('leads.contact.title')}
        progress={0}
        hideProgress
        actions={<Actions />}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TrustBox trustPoints={[
              t('leads.contact.trustBox1'),
              t('leads.contact.trustBox2'),
              t('leads.contact.trustBox3'),
              t('leads.contact.trustBox4'),
              t('leads.contact.trustBox5')
            ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  name='firstName'
                  label={t('common:inputs.firstName')}
                  variant='outlined'
                  className={classes.textField}
                  fullWidth
                  inputRef={register(required)}
                  error={!!errors?.firstName}
                  helperText={errors?.firstName?.message}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name='lastName'
                  label={t('common:inputs.lastName')}
                  variant='outlined'
                  className={classes.textField}
                  fullWidth
                  inputRef={register(required)}
                  error={!!errors?.lastName}
                  helperText={errors?.lastName?.message}
                />
              </Grid>
            </Grid>

            <TextField
              name='companyName'
              label={t('common:inputs.companyName')}
              variant='outlined'
              className={classes.textField}
              fullWidth
              inputRef={register()}
              error={!!errors?.companyName}
              helperText={errors?.companyName?.message}
            />
            <TextField
              name='companyHomepage'
              label={t('common:inputs.companyHomepage')}
              variant='outlined'
              className={classes.textField}
              fullWidth
              inputRef={register({
                validate: value => value ? isURL(value) || t('errors.urlInvalid') : true
              })}
              error={!!errors?.companyHomepage}
              helperText={errors?.companyHomepage?.message}
            />
            <TextField
              name='email'
              label={t('common:inputs.email')}
              className={classes.textField}
              fullWidth
              variant='outlined'
              inputRef={register(email)}
              error={!!errors?.email}
              helperText={errors?.email?.message}
            />
            <TextField
              name='phoneNumber'
              label={t('common:inputs.phone')}
              fullWidth
              className={classes.textField}
              variant='outlined'
              inputRef={register({ minLength: 8, maxLength: 20 })}
              error={!!errors?.phoneNumber}
              helperText={errors?.phoneNumber?.message}
            />
          </Grid>

        </Grid>
      </FunnelQuestion>
    </form>
  )
}

export default Final
