"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _snackbar = require("../contexts/snackbar.context");

var useSnackbar = function useSnackbar() {
  return (0, _react.useContext)(_snackbar.SnackbarContext);
};

var _default = useSnackbar;
exports["default"] = _default;