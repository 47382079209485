//  background: `url(${BlobsBackground}) center / cover no-repeat;`
import React, { useContext } from 'react'
import { Layouts } from '@leanlancer/ui'
import { LanguageContext } from '@leanlancer/common'
import { makeStyles } from '@material-ui/core/styles'
import BlobsBackground from '@leanlancer/ui/dist/svgs/background-blobs.svg'

const useStyles = makeStyles(theme => ({
  root: {
    background: `url(${BlobsBackground}) center / cover no-repeat`
  }
}))

const Funnel = ({ children }) => {
  const { lang, changeLanguage } = useContext(LanguageContext)
  const classes = useStyles()
  return (
    <Layouts.Minimal
      topBarProps={{
        homeHref: 'https://leanlancer.com/',
        onLangChange: changeLanguage,
        lang
      }}
      className={classes.root}
    >
      {children}
    </Layouts.Minimal>
  )
}

export default Funnel
