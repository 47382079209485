import { Flex } from '@leanlancer/ui'
import { FunnelQuestion } from '@leanlancer/ui/dist/modules/LeadFunnel'
import { Button, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { required } from '../../../common/validators'
import useLeadData from '../../../hooks/useLeadData'

const AlmostThere = ({ goNext, ...props }) => {
  // const classes = useStyles()
  const { t } = useTranslation('client')
  const [leadSnap, leadData] = useLeadData()
  const { register, handleSubmit, errors } = useForm({
    defaultValues: leadData
  })

  const onSubmit = (values, event) => {
    leadSnap.ref.update({
      type: 'default',
      ...values
    })
    goNext('verification')
  }

  const Actions = (
    <Flex dir='rtl'>
      <Button
        variant='outlined'
        color='primary'
        type='submit'
      >{t('common:inputs.next')}
      </Button>
    </Flex>
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <FunnelQuestion
        title={t('leads.almostThere.title')}
        progress={60}
        actions={Actions}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant='h4' gutterBottom>{t('leads.almostThere.projectPeriod')}</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  label={t('leads.almostThere.projectStart')}
                  name='projectStart'
                  type='date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  inputRef={register()}
                  error={!!errors?.projectStart}
                  helperText={errors?.projectStart?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  label={t('leads.almostThere.projectEnd')}
                  name='projectEnd'
                  type='date'
                  InputLabelProps={{
                    shrink: true
                  }}
                  fullWidth
                  inputRef={register()}
                  error={!!errors?.projectEnd}
                  helperText={errors?.projectEnd?.message}
                />
              </Grid>
            </Grid>

          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='h4' gutterBottom>{t('leads.almostThere.approxBudget')}</Typography>
            <TextField
              variant='outlined'
              name='approxBudget'
              type='number'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    €
                  </InputAdornment>
                )
              }}
              fullWidth
              inputRef={register(required)}
              error={!!errors?.approxBudget}
              helperText={errors?.approxBudget?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4' gutterBottom>{t('leads.almostThere.additionalInfo')}</Typography>
            <TextField
              variant='outlined'
              fullWidth
              multiline
              rows={4}
              name='additionalInfo'
              inputRef={register}
              error={!!errors?.additionalInfo}
              helperText={errors?.additionalInfo?.message}
            />
          </Grid>
        </Grid>
      </FunnelQuestion>
    </form>
  )
}

export default AlmostThere
