"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _GoogleSignInButton = _interopRequireDefault(require("./GoogleSignInButton"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = _GoogleSignInButton.default;
exports.default = _default;