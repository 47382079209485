"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// import palette from '../palette'
// import MuiButton from './MuiButton'
// import MuiFormLabel from './MuiFormLabel'
// import MuiIconButton from './MuiIconButton'
// import MuiPaper from './MuiPaper'
// import MuiTableCell from './MuiTableCell'
// import MuiTableHead from './MuiTableHead'
// import MuiTypography from './MuiTypography'
var _default = {// MuiButton,
  // MuiFormLabel,
  // MuiIconButton,
  // MuiPaper,
  // MuiTableCell,
  // MuiTableHead,
  // MuiTypography
};
exports.default = _default;