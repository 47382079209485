import {
  Badge,
  Box,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2)
  },
  listItem: {
    justifyContent: 'space-between'
  },
  offerTitle: {
    marginRight: theme.spacing(2),
    fontSize: '1.5rem'
  },
  noOffersMessage: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  activeBadge: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(2)
  },
  expiredBadge: {
    // color: theme.palette.getContrastText(red[500]),
    // backgroundColor: red[500],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    marginRight: theme.spacing(2)
  }
}))

const JobOffersListView = () => {
  const classes = useStyles()
  const history = useHistory()
  const [jobOffers, setJobOffers] = useState([])
  const { t } = useTranslation(['client', 'common'])

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('job_offers')
      .onSnapshot((snapshot) => {
        const jobOffersData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        setJobOffers(jobOffersData)
      })

    return () => {
      unsubscribe()
    }
  }, [])

  const handleJobOfferClick = (jobOfferId) => {
    history.push(`/job-offers/${jobOfferId}`) // Replace with the desired detail view route
  }

  const handleAddJobOffer = () => {
    history.push('/job-offers/create') // Replace with the desired create view route
  }

  const getBadgeClass = (isActive) => {
    return isActive ? classes.activeBadge : classes.expiredBadge
  }

  if (jobOffers.length === 0) {
    return (
      <Typography variant='body1' className={classes.noOffersMessage}>
        {t('client:jobOffers.noOffers')}
      </Typography>
    )
  }

  return (
    <>
      <Toolbar className={classes.toolbar}>
        <Button onClick={handleAddJobOffer}>
          {t('client:jobOffers.addJobOffer')}
        </Button>
      </Toolbar>
      <List>
        {jobOffers.map((jobOffer) => (
          <ListItem
            key={jobOffer.id}
            className={classes.listItem}
            button
            onClick={() => handleJobOfferClick(jobOffer.id)}
          >
            <ListItemText
              primary={
                <>
                  <Typography variant='subtitle1' className={classes.offerTitle}>
                    {jobOffer.title}
                  </Typography>
                </>
              }
              secondary={jobOffer.company}
            />
            <ListItemSecondaryAction>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
              >
                <Box>
                  <Badge
                    classes={{ badge: getBadgeClass(jobOffer.isActive) }}
                    badgeContent={jobOffer.isActive ? 'Active' : 'Inactive'}
                  />
                </Box>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export default JobOffersListView
