import { Flex } from '@leanlancer/ui'
import { FunnelQuestion, FunnelSelectCardGrid } from '@leanlancer/ui/dist/modules/LeadFunnel'
import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import useLeadData from '../../../hooks/useLeadData'
import { useTranslation } from 'react-i18next'

import Copywriting from '../icons/copywriting.svg'
import GraphicDesign from '../icons/graphic-design.svg'
import OnlineMarketing from '../icons/online-marketing.svg'
import Other from '../icons/other.svg'
import Translation from '../icons/translation.svg'
import WebDevelopment from '../icons/web-development.svg'

const Services = ({ goNext, ...props }) => {
  // const classes = useStyles()
  const { t } = useTranslation('client')
  const [leadSnap, leadData] = useLeadData()
  const [selected, setSelected] = useState(leadData?.services || [])

  const handleSelectItem = (item) => (e) => {
    e.preventDefault()
    if (selected.includes(item)) {
      setSelected(selected.filter(i => i !== item))
    } else {
      setSelected([...selected, item])
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    leadSnap.ref.update({
      type: 'default',
      services: selected
    })
    goNext('describe-request')
  }

  const Actions = () => (
    <Flex dir='rtl'>
      <Button
        disabled={selected.length === 0}
        variant='outlined'
        color='primary'
        onClick={handleSubmit}
      >{t('common:inputs.next')}
      </Button>
    </Flex>
  )

  return (
    <>
      <FunnelQuestion
        title={t('leads.services.title')}
        subtitle={t('leads.services.subtitle')}
        actions={<Actions />}
        progress={20}
      >
        <FunnelSelectCardGrid
          cards={[
            {
              onSelect: handleSelectItem('online-marketing'),
              selected: selected.includes('online-marketing'),
              title: t('leads.services.onlineMarketing'),
              imgSrc: OnlineMarketing
            },
            {
              onSelect: handleSelectItem('web-development'),
              selected: selected.includes('web-development'),
              title: t('leads.services.development'),
              imgSrc: WebDevelopment
            },
            {
              onSelect: handleSelectItem('graphic-design'),
              selected: selected.includes('graphic-design'),
              title: t('leads.services.graphicDesign'),
              imgSrc: GraphicDesign
            },
            {
              onSelect: handleSelectItem('copywriting'),
              selected: selected.includes('copywriting'),
              title: t('leads.services.copywriting'),
              imgSrc: Copywriting
            },
            {
              onSelect: handleSelectItem('translations'),
              selected: selected.includes('translations'),
              title: t('leads.services.translations'),
              imgSrc: Translation
            },
            {
              onSelect: handleSelectItem('other'),
              selected: selected.includes('other'),
              title: t('leads.services.other'),
              imgSrc: Other
            }
          ]}
        />
      </FunnelQuestion>
    </>
  )
}

export default Services
