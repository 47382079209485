import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AssignmentIcon from '@material-ui/icons/Assignment'
import HomeIcon from '@material-ui/icons/Home'
import ReceiptIcon from '@material-ui/icons/Receipt'
import SettingsIcon from '@material-ui/icons/Settings'
import WorkIcon from '@material-ui/icons/Work'
import React from 'react'
import { RouteWithLayout } from '../components'
import {
  Dashboard as DashboardLayout,
  Funnel as FunnelLayout,
  Minimal as MinimalLayout
} from '../layouts'
import {
  ClientInvoices,
  Home,
  Leads,
  NotFound,
  Profile,
  Projects,
  Settings,
  SignIn,
  SignUp
} from '../views'
import JobOffers from '../views/JobOffers'
import JobOfferCreateView from '../views/JobOffers/JobOfferCreateView'
import JobOfferEditView from '../views/JobOffers/JobOfferEditView'

export default {
  LEAD: {
    render: (
      <RouteWithLayout
        key='LEADS'
        component={Leads}
        layout={FunnelLayout}
        path='/leads'
      />
    )
  },
  HOME: {
    menu: {
      title: 'home',
      href: '/',
      icon: <HomeIcon />
    },
    render: (
      <RouteWithLayout
        key='HOME'
        reserved
        component={Home}
        exact
        layout={DashboardLayout}
        path='/'
      />
    )
  },
  PROJECTS: {
    menu: {
      title: 'projects',
      href: '/projects',
      icon: <AssignmentIcon />
    },
    render: (
      <RouteWithLayout
        key='PROJECTS'
        reserved
        exact
        component={Projects}
        layout={DashboardLayout}
        path='/projects'
      />
    )
  },
  PROJECTDETAIL: {
    render: (
      <RouteWithLayout
        key='PROJECTDETAIL'
        reserved
        component={Projects}
        layout={DashboardLayout}
        path='/projects/:id'
      />
    )
  },
  INVOICES: {
    menu: {
      title: 'invoices',
      href: '/invoices',
      icon: <ReceiptIcon />
    },
    render: (
      <RouteWithLayout
        key='INVOICES'
        reserved
        exact
        component={ClientInvoices}
        layout={DashboardLayout}
        path='/invoices'
      />
    )
  },
  INVOICEDETAIL: {
    render: (
      <RouteWithLayout
        key='INVOICEDETAIL'
        reserved
        component={ClientInvoices}
        layout={DashboardLayout}
        path='/invoices/:id'
      />
    )
  },
  JOB_OFFERS: {
    menu: {
      title: 'jobOffers',
      href: '/job-offers',
      icon: <WorkIcon />
    },
    render: (
      <RouteWithLayout
        key='JOB_OFFERS'
        reserved
        component={JobOffers}
        exact
        layout={DashboardLayout}
        path='/job-offers'
      />
    )
  },
  JOB_OFFER_CREATE: {
    render: (
      <RouteWithLayout
        key='JOB_OFFER_CREATE'
        reserved
        exact
        component={JobOfferCreateView}
        layout={DashboardLayout}
        path='/job-offers/create'
      />
    )
  },
  JOB_OFFER_EDIT: {
    render: (
      <RouteWithLayout
        key='JOB_OFFER_EDIT'
        reserved
        component={JobOfferEditView}
        layout={DashboardLayout}
        path='/job-offers/edit/:id'
      />
    )
  },
  JOB_OFFER_DETAIL: {
    render: (
      <RouteWithLayout
        key='JOB_OFFER_DETAIL'
        reserved
        component={JobOffers}
        layout={DashboardLayout}
        path='/job-offers/:id'
      />
    )
  },
  PROFILE: {
    menu: {
      title: 'profile',
      href: '/profile',
      icon: <AccountBoxIcon />
    },
    render: (
      <RouteWithLayout
        key='PROFILE'
        reserved
        component={Profile}
        exact
        layout={DashboardLayout}
        path='/profile'
      />
    )
  },
  SETTINGS: {
    menu: {
      title: 'settings',
      href: '/settings',
      icon: <SettingsIcon />
    },
    render: (
      <RouteWithLayout
        key='SETTINGS'
        reserved
        component={Settings}
        exact
        layout={DashboardLayout}
        path='/settings'
      />
    )
  },
  SIGN_UP: {
    render: (
      <RouteWithLayout
        key='SIGN_UP'
        component={SignUp}
        exact
        layout={MinimalLayout}
        path='/sign-up'
      />
    )
  },
  SIGN_IN: {
    render: (
      <RouteWithLayout
        key='SIGN_IN'
        component={SignIn}
        exact
        layout={MinimalLayout}
        path='/sign-in'
      />
    )
  },
  NOT_FOUND: {
    render: (
      <RouteWithLayout
        key='NOT_FOUND'
        component={NotFound}
        exact
        layout={MinimalLayout}
        path='/not-found'
      />
    )
  }
}
