import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Typography,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Box,
  Badge
} from '@material-ui/core'
import { amber, green, red } from '@material-ui/core/colors'
import { useTranslation } from 'react-i18next'

const TAX_RATE = 0.19

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  invoiceNumber: {
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  section: {
    marginBottom: theme.spacing(2)
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  totalAmount: {
    marginTop: theme.spacing(2),
    fontSize: '1.1rem'
  },
  paymentDetails: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    textAlign: 'right'
  },
  statusBadge: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5, 1),
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeightBold,
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(4)
  },
  unpaidBadge: {
    color: theme.palette.getContrastText(amber[500]),
    backgroundColor: amber[500]
  },
  paidBadge: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500]
  },
  overdueBadge: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500]
  }
}))

const InvoiceDetailView = () => {
  const classes = useStyles()
  const { id: invoiceId } = useParams()
  const [invoice, setInvoice] = useState(null)
  const { t } = useTranslation('client')

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const docRef = await firebase.firestore().collection('client_invoices').doc(invoiceId).get()
        if (docRef.exists) {
          setInvoice(docRef.data())
        } else {
          setInvoice(null)
        }
      } catch (error) {
        console.error('Error fetching invoice:', error)
        setInvoice(null)
      }
    }

    fetchInvoice()
  }, [invoiceId])

  if (!invoice) {
    return (
      <Typography variant='body1'>
        Invoice not found.
      </Typography>
    )
  }

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(amount)
  }

  const calculateSubtotal = () => {
    if (invoice.items) {
      return invoice.items.reduce((total, item) => total + item.unitPrice, 0)
    }
    return 0
  }

  const calculateTaxAmount = () => {
    return calculateSubtotal() * TAX_RATE
  }

  const calculateTotalAmount = () => {
    return calculateSubtotal() + calculateTaxAmount()
  }

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 'unpaid':
        return [classes.statusBadge, classes.unpaidBadge].join(' ')
      case 'paid':
        return [classes.statusBadge, classes.paidBadge].join(' ')
      case 'overdue':
        return [classes.statusBadge, classes.overdueBadge].join(' ')
      default:
        return classes.statusBadge
    }
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.invoiceNumber}>
        <Typography variant='h4'>
          {`${t('client:invoices.invoiceNumber')} INV-` + invoice.invoiceNumber.toString().padStart(3, '0')}
        </Typography>
        <Badge
          classes={{ badge: getStatusBadgeClass(invoice.status) }}
          badgeContent={invoice.status.toUpperCase()}
        />
      </div>
      <Grid container spacing={2} className={classes.section}>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            {t('client:invoices.clientName')}: {invoice.clientName || '-'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>
            {t('client:invoices.issueDate')}: {invoice.issueDate || '-'}
          </Typography>
          <Typography variant='subtitle1'>
            {t('client:invoices.dueDate')}: {invoice.dueDate || '-'}
          </Typography>
        </Grid>
      </Grid>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('client:invoices.description')}</TableCell>
              <TableCell align='right'>{t('client:invoices.quantity')}</TableCell>
              <TableCell align='right'>{t('client:invoices.unitPrice')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.items && invoice.items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.description}</TableCell>
                <TableCell align='right'>{item.quantity}</TableCell>
                <TableCell align='right'>{formatCurrency(item.unitPrice)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display='flex' justifyContent='flex-end'>
        <Typography variant='subtitle1'>
          {t('client:invoices.subtotal')}: {formatCurrency(calculateSubtotal())}
        </Typography>
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <Typography variant='subtitle1'>
          {t('client:invoices.tax')} ({(TAX_RATE * 100).toFixed(2)}%): {formatCurrency(calculateTaxAmount())}
        </Typography>
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <Typography variant='h5' className={classes.totalAmount}>
          {t('client:invoices.total')}: {formatCurrency(calculateTotalAmount())}
        </Typography>
      </Box>
      <div className={classes.paymentDetails}>
        <Typography variant='subtitle2' className={classes.section}>
          {t('client:invoices.paymentDetails')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant='body2'>
              {t('client:invoices.businessName')}: LeanLancer UG (haftungsbeschränkt)
            </Typography>
            <Typography variant='body2'>
              {t('client:invoices.address')}: Bülowstraße 6, 10783 Berlin, Deutschland
            </Typography>
            <Typography variant='body2'>
              {t('client:invoices.phone')}: 030 9210057 70
            </Typography>
            <Typography variant='body2'>
              {t('client:invoices.email')}: contact@leanlancer.com
            </Typography>
            <Typography variant='body2'>
              {t('client:invoices.website')}: https://leanlancer.com/
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='body2'>
              {t('client:invoices.bank')}: Commerzbank
            </Typography>
            <Typography variant='body2'>
              IBAN: DE34 1004 0000 0885 4606 00
            </Typography>
            <Typography variant='body2'>
              BIC: COBADEFFXXX
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='body2'>
              Charlottenburg HR-Nr.: HRB 226680
            </Typography>
            <Typography variant='body2'>
              USt.-ID: DE341416763
            </Typography>
            <Typography variant='body2'>
              Steuer-Nr.: 29/414/31316
            </Typography>
            <Typography variant='body2'>
              Geschäftsführung: Nils Beyer
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}

export default InvoiceDetailView
